function api() {
    function e() {
        return 2 * parseInt(o.css("padding-left"))
    }

    function t(e) {
        $window.width() >= e ? c.addClass("hide") : c.removeClass("hide")
    }

    function n(n) {
        var a = s.filter('[data-language="' + n + '"]'),
            i = a.find("pre");
        s.removeClass("show-flex"), a.addClass("show-flex"), u = i.find("code").outerWidth() + 75 + e(), c = i.find(".fs-scrollbar-bar"), t(u)
    }
    var a = $(".language_select"),
        i = $(".language_button"),
        s = $(".language_code"),
        r = $(".show-flex").find("pre").scrollbar({
            horizontal: !0
        }),
        o = $("#developer_api"),
        l = 2 * parseInt(o.css("padding-left")),
        u = r.find("code").outerWidth() + 75 + l,
        c = r.find(".fs-scrollbar-bar");
    a.change(function() {
        var e = $(this),
            t = e.val();
        n(t), i.removeClass("active"), i.filter('[data-language="' + t + '"]').addClass("active")
    }), i.on("click", function() {
        var e = $(this),
            t = e.attr("data-language");
        n(t), i.removeClass("active"), e.addClass("active"), a.val(t)
    }), $window.on("resize", function() {
        u = $(".show-flex").find("code").outerWidth() + 75 + e(), $window.width() >= u ? c.addClass("hide") : c.removeClass("hide")
    }), t(u)
}

function planChange() {
    var e = $(".subscription_select"),
        t = $(".ppe_select"),
        n = $(".month_price"),
        a = $(".per_email_price"),
        i = $(".additional_price");
    $(".chosen_plan");
    e.dropdown(), t.dropdown(), e.on("change", function() {
        var e = $(this),
            t = e.val(),
            a = e.find('[value="' + t + '"]'),
            s = a.attr("data-amount"),
            r = a.attr("data-cpe");
        n.text(s), i.text(r)
    }), t.on("change", function() {
        var e = $(this),
            t = e.val(),
            n = e.find('[value="' + t + '"]'),
            i = n.attr("data-cpe");
        a.text(i)
    })
}

function volumeRateAdjust() {
    var e = $(".abbreivate");
    $window.width() > 374 ? e.each(function() {
        $(this).text($(this).attr("data-long"))
    }) : e.each(function() {
        $(this).text($(this).attr("data-short"))
    }), $window.on("resize", function() {
        $window.width() > 374 ? e.each(function() {
            $(this).text($(this).attr("data-long"))
        }) : e.each(function() {
            $(this).text($(this).attr("data-short"))
        })
    })
}

function setCurrentDate() {
    var e = new Date,
        t = e.getMonth(),
        n = e.getFullYear(),
        a = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    $(".current_date").each(function() {
        var e = a[t] + "_" + n + ".csv";
        $(this).text(e)
    })
}

function listSourceLoop() {
    var e = $(".source_list li").toArray(),
        t = 0;
    setInterval(function() {
        for (t >= e.length && (t = 0), i = 0; i < e.length; i++) {
            $(e[i]).removeClass("active")
        }
        $(e[t]).addClass("active"), t++
    }, 3e3)
}

function removeAnimation() {
    $(".remove_animation").each(function() {
        var e = $(this),
            t = e.attr("data-duration");
        e.hasClass("desktop_only") && $window.width() <= desktop && e.css("animation", "none"), setTimeout(function() {
            e.css("animation", "none")
        }, t + 200)
    })
}

function addScrollbar() {
    $("pre").each(function() {
        $(this).scrollbar({
            horizontal: !0
        })
    })
}

function returnTomorrow() {
	var today = new Date();
	var tomorrow = new Date();
	tomorrow.setDate(today.getDate()+1);
	var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return tomorrow.toLocaleDateString("en-US", options)+' at 9:00AM';
}

function homepageDemo() {
    if ($(".demo_text").length) {
        var e = ($(".demo_icon"), $(".demo_icon--deliverable")),
            t = $(".demo_icon--undeliverable"),
            n = ["Hi, John. We reviewed your resume and would like to invite you in for an interview.","Robert, I just wanted to send a quick confirmation for your scheduled interview on "+returnTomorrow()],
            a = {
                strings: ["Hi, John. We reviewed your resume and would like to invite you in for an interview.","Hello Jennifer, I just wanted to send a quick confirmation for your scheduled interview on "+returnTomorrow()],
                typeSpeed: 40,
                backSpeed: 1,
                backDelay: 2500,
                startDelay: 0,
                loop: !0,
                showCursor: !0,
                onStringTyped: function(a, i) {
                    n.includes(i.el.innerText) ? (e.removeClass("active"), t.addClass("active"), setTimeout(function() {
                        t.addClass("no_animation")
                    }, 820)) : (e.addClass("active"), t.removeClass("active"), setTimeout(function() {
                        t.removeClass("no_animation")
                    }, 820))
                }
            };
        new Typed(".demo_text", a)
    }
}

function menu() {
    var e, t = $("#nav_trigger"),
        n = $("nav"),
        a = [n, n.find(".products_dropdown_container"), n.find(".product"), n.find("svg"), n.find("span"), n.find(".primary_nav_item"), n.find("a")];
    setTimeout(function() {
        $.each(a, function(e) {
            a[e].removeClass("no_transition")
        })
    }, 250), t.on("click", function(e) {
        $(this).toggleClass("active"), e.stopPropagation(), n.toggleClass("active")
    }), $document.on("click tap touch", function(e) {
        $(e.target).parents("nav").length || (n.removeClass("active"), t.removeClass("active"))
    }), navigator.userAgent.match(/(iPod|iPhone|iPad)/i) && $body.addClass("is-ios"), $window.on("resize", function() {
        $.each(a, function(e) {
            a[e].addClass("no_transition")
        }), clearTimeout(e), e = setTimeout(function() {
            $.each(a, function(e) {
                a[e].removeClass("no_transition")
            })
        }, 500), $window.width() > 768 && (n.removeClass("active"), t.removeClass("active"))
    })
}
var _self = "undefined" != typeof window ? window : "undefined" != typeof WorkerGlobalScope && self instanceof WorkerGlobalScope ? self : {},
    Prism = function() {
        var e = /\blang(?:uage)?-(\w+)\b/i,
            t = 0,
            n = _self.Prism = {
                manual: _self.Prism && _self.Prism.manual,
                disableWorkerMessageHandler: _self.Prism && _self.Prism.disableWorkerMessageHandler,
                util: {
                    encode: function(e) {
                        return e instanceof a ? new a(e.type, n.util.encode(e.content), e.alias) : "Array" === n.util.type(e) ? e.map(n.util.encode) : e.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/\u00a0/g, " ")
                    },
                    type: function(e) {
                        return Object.prototype.toString.call(e).match(/\[object (\w+)\]/)[1]
                    },
                    objId: function(e) {
                        return e.__id || Object.defineProperty(e, "__id", {
                            value: ++t
                        }), e.__id
                    },
                    clone: function(e, t) {
                        var a = n.util.type(e);
                        switch (t = t || {}, a) {
                            case "Object":
                                if (t[n.util.objId(e)]) return t[n.util.objId(e)];
                                var i = {};
                                t[n.util.objId(e)] = i;
                                for (var s in e) e.hasOwnProperty(s) && (i[s] = n.util.clone(e[s], t));
                                return i;
                            case "Array":
                                if (t[n.util.objId(e)]) return t[n.util.objId(e)];
                                var i = [];
                                return t[n.util.objId(e)] = i, e.forEach(function(e, a) {
                                    i[a] = n.util.clone(e, t)
                                }), i
                        }
                        return e
                    }
                },
                languages: {
                    extend: function(e, t) {
                        var a = n.util.clone(n.languages[e]);
                        for (var i in t) a[i] = t[i];
                        return a
                    },
                    insertBefore: function(e, t, a, i) {
                        i = i || n.languages;
                        var s = i[e];
                        if (2 == arguments.length) {
                            a = arguments[1];
                            for (var r in a) a.hasOwnProperty(r) && (s[r] = a[r]);
                            return s
                        }
                        var o = {};
                        for (var l in s)
                            if (s.hasOwnProperty(l)) {
                                if (l == t)
                                    for (var r in a) a.hasOwnProperty(r) && (o[r] = a[r]);
                                o[l] = s[l]
                            } return n.languages.DFS(n.languages, function(t, n) {
                            n === i[e] && t != e && (this[t] = o)
                        }), i[e] = o
                    },
                    DFS: function(e, t, a, i) {
                        i = i || {};
                        for (var s in e) e.hasOwnProperty(s) && (t.call(e, s, e[s], a || s), "Object" !== n.util.type(e[s]) || i[n.util.objId(e[s])] ? "Array" !== n.util.type(e[s]) || i[n.util.objId(e[s])] || (i[n.util.objId(e[s])] = !0, n.languages.DFS(e[s], t, s, i)) : (i[n.util.objId(e[s])] = !0, n.languages.DFS(e[s], t, null, i)))
                    }
                },
                plugins: {},
                highlightAll: function(e, t) {
                    n.highlightAllUnder(document, e, t)
                },
                highlightAllUnder: function(e, t, a) {
                    var i = {
                        callback: a,
                        selector: 'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code'
                    };
                    n.hooks.run("before-highlightall", i);
                    for (var s, r = i.elements || e.querySelectorAll(i.selector), o = 0; s = r[o++];) n.highlightElement(s, !0 === t, i.callback)
                },
                highlightElement: function(t, a, i) {
                    for (var s, r, o = t; o && !e.test(o.className);) o = o.parentNode;
                    o && (s = (o.className.match(e) || [, ""])[1].toLowerCase(), r = n.languages[s]), t.className = t.className.replace(e, "").replace(/\s+/g, " ") + " language-" + s, t.parentNode && (o = t.parentNode, /pre/i.test(o.nodeName) && (o.className = o.className.replace(e, "").replace(/\s+/g, " ") + " language-" + s));
                    var l = t.textContent,
                        u = {
                            element: t,
                            language: s,
                            grammar: r,
                            code: l
                        };
                    if (n.hooks.run("before-sanity-check", u), !u.code || !u.grammar) return u.code && (n.hooks.run("before-highlight", u), u.element.textContent = u.code, n.hooks.run("after-highlight", u)), void n.hooks.run("complete", u);
                    if (n.hooks.run("before-highlight", u), a && _self.Worker) {
                        var c = new Worker(n.filename);
                        c.onmessage = function(e) {
                            u.highlightedCode = e.data, n.hooks.run("before-insert", u), u.element.innerHTML = u.highlightedCode, i && i.call(u.element), n.hooks.run("after-highlight", u), n.hooks.run("complete", u)
                        }, c.postMessage(JSON.stringify({
                            language: u.language,
                            code: u.code,
                            immediateClose: !0
                        }))
                    } else u.highlightedCode = n.highlight(u.code, u.grammar, u.language), n.hooks.run("before-insert", u), u.element.innerHTML = u.highlightedCode, i && i.call(t), n.hooks.run("after-highlight", u), n.hooks.run("complete", u)
                },
                highlight: function(e, t, i) {
                    var s = {
                        text: e,
                        grammar: t,
                        language: i
                    };
                    return s.tokens = n.tokenize(e, t), n.hooks.run("after-tokenize", s), a.stringify(n.util.encode(s.tokens), i)
                },
                matchGrammar: function(e, t, a, i, s, r, o) {
                    var l = n.Token;
                    for (var u in a)
                        if (a.hasOwnProperty(u) && a[u]) {
                            if (u == o) return;
                            var c = a[u];
                            c = "Array" === n.util.type(c) ? c : [c];
                            for (var d = 0; d < c.length; ++d) {
                                var p = c[d],
                                    h = p.inside,
                                    f = !!p.lookbehind,
                                    g = !!p.greedy,
                                    m = 0,
                                    b = p.alias;
                                if (g && !p.pattern.global) {
                                    var v = p.pattern.toString().match(/[imuy]*$/)[0];
                                    p.pattern = RegExp(p.pattern.source, v + "g")
                                }
                                p = p.pattern || p;
                                for (var y = i, w = s; y < t.length; w += t[y].length, ++y) {
                                    var k = t[y];
                                    if (t.length > e.length) return;
                                    if (!(k instanceof l)) {
                                        p.lastIndex = 0;
                                        var $ = p.exec(k),
                                            x = 1;
                                        if (!$ && g && y != t.length - 1) {
                                            if (p.lastIndex = w, !($ = p.exec(e))) break;
                                            for (var C = $.index + (f ? $[1].length : 0), P = $.index + $[0].length, S = y, _ = w, T = t.length; S < T && (_ < P || !t[S].type && !t[S - 1].greedy); ++S) _ += t[S].length, C >= _ && (++y, w = _);
                                            if (t[y] instanceof l || t[S - 1].greedy) continue;
                                            x = S - y, k = e.slice(w, _), $.index -= w
                                        }
                                        if ($) {
                                            f && (m = $[1] ? $[1].length : 0);
                                            var C = $.index + m,
                                                $ = $[0].slice(m),
                                                P = C + $.length,
                                                I = k.slice(0, C),
                                                M = k.slice(P),
                                                E = [y, x];
                                            I && (++y, w += I.length, E.push(I));
                                            var O = new l(u, h ? n.tokenize($, h) : $, b, $, g);
                                            if (E.push(O), M && E.push(M), Array.prototype.splice.apply(t, E), 1 != x && n.matchGrammar(e, t, a, y, w, !0, u), r) break
                                        } else if (r) break
                                    }
                                }
                            }
                        }
                },
                tokenize: function(e, t) {
                    var a = [e],
                        i = t.rest;
                    if (i) {
                        for (var s in i) t[s] = i[s];
                        delete t.rest
                    }
                    return n.matchGrammar(e, a, t, 0, 0, !1), a
                },
                hooks: {
                    all: {},
                    add: function(e, t) {
                        var a = n.hooks.all;
                        a[e] = a[e] || [], a[e].push(t)
                    },
                    run: function(e, t) {
                        var a = n.hooks.all[e];
                        if (a && a.length)
                            for (var i, s = 0; i = a[s++];) i(t)
                    }
                }
            },
            a = n.Token = function(e, t, n, a, i) {
                this.type = e, this.content = t, this.alias = n, this.length = 0 | (a || "").length, this.greedy = !!i
            };
        if (a.stringify = function(e, t, i) {
                if ("string" == typeof e) return e;
                if ("Array" === n.util.type(e)) return e.map(function(n) {
                    return a.stringify(n, t, e)
                }).join("");
                var s = {
                    type: e.type,
                    content: a.stringify(e.content, t, i),
                    tag: "span",
                    classes: ["token", e.type],
                    attributes: {},
                    language: t,
                    parent: i
                };
                if (e.alias) {
                    var r = "Array" === n.util.type(e.alias) ? e.alias : [e.alias];
                    Array.prototype.push.apply(s.classes, r)
                }
                n.hooks.run("wrap", s);
                var o = Object.keys(s.attributes).map(function(e) {
                    return e + '="' + (s.attributes[e] || "").replace(/"/g, "&quot;") + '"'
                }).join(" ");
                return "<" + s.tag + ' class="' + s.classes.join(" ") + '"' + (o ? " " + o : "") + ">" + s.content + "</" + s.tag + ">"
            }, !_self.document) return _self.addEventListener ? (n.disableWorkerMessageHandler || _self.addEventListener("message", function(e) {
            var t = JSON.parse(e.data),
                a = t.language,
                i = t.code,
                s = t.immediateClose;
            _self.postMessage(n.highlight(i, n.languages[a], a)), s && _self.close()
        }, !1), _self.Prism) : _self.Prism;
        var i = document.currentScript || [].slice.call(document.getElementsByTagName("script")).pop();
        return i && (n.filename = i.src, n.manual || i.hasAttribute("data-manual") || ("loading" !== document.readyState ? window.requestAnimationFrame ? window.requestAnimationFrame(n.highlightAll) : window.setTimeout(n.highlightAll, 16) : document.addEventListener("DOMContentLoaded", n.highlightAll))), _self.Prism
    }();
"undefined" != typeof module && module.exports && (module.exports = Prism), "undefined" != typeof global && (global.Prism = Prism), Prism.languages.markup = {
        comment: /<!--[\s\S]*?-->/,
        prolog: /<\?[\s\S]+?\?>/,
        doctype: /<!DOCTYPE[\s\S]+?>/i,
        cdata: /<!\[CDATA\[[\s\S]*?]]>/i,
        tag: {
            pattern: /<\/?(?!\d)[^\s>\/=$<%]+(?:\s+[^\s>\/=]+(?:=(?:("|')(?:\\[\s\S]|(?!\1)[^\\])*\1|[^\s'">=]+))?)*\s*\/?>/i,
            greedy: !0,
            inside: {
                tag: {
                    pattern: /^<\/?[^\s>\/]+/i,
                    inside: {
                        punctuation: /^<\/?/,
                        namespace: /^[^\s>\/:]+:/
                    }
                },
                "attr-value": {
                    pattern: /=(?:("|')(?:\\[\s\S]|(?!\1)[^\\])*\1|[^\s'">=]+)/i,
                    inside: {
                        punctuation: [/^=/, {
                            pattern: /(^|[^\\])["']/,
                            lookbehind: !0
                        }]
                    }
                },
                punctuation: /\/?>/,
                "attr-name": {
                    pattern: /[^\s>\/]+/,
                    inside: {
                        namespace: /^[^\s>\/:]+:/
                    }
                }
            }
        },
        entity: /&#?[\da-z]{1,8};/i
    }, Prism.languages.markup.tag.inside["attr-value"].inside.entity = Prism.languages.markup.entity, Prism.hooks.add("wrap", function(e) {
        "entity" === e.type && (e.attributes.title = e.content.replace(/&amp;/, "&"))
    }), Prism.languages.xml = Prism.languages.markup, Prism.languages.html = Prism.languages.markup, Prism.languages.mathml = Prism.languages.markup, Prism.languages.svg = Prism.languages.markup, Prism.languages.css = {
        comment: /\/\*[\s\S]*?\*\//,
        atrule: {
            pattern: /@[\w-]+?.*?(?:;|(?=\s*\{))/i,
            inside: {
                rule: /@[\w-]+/
            }
        },
        url: /url\((?:(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1|.*?)\)/i,
        selector: /[^{}\s][^{};]*?(?=\s*\{)/,
        string: {
            pattern: /("|')(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
            greedy: !0
        },
        property: /[-_a-z\xA0-\uFFFF][-\w\xA0-\uFFFF]*(?=\s*:)/i,
        important: /\B!important\b/i,
        function: /[-a-z0-9]+(?=\()/i,
        punctuation: /[(){};:]/
    }, Prism.languages.css.atrule.inside.rest = Prism.languages.css, Prism.languages.markup && (Prism.languages.insertBefore("markup", "tag", {
        style: {
            pattern: /(<style[\s\S]*?>)[\s\S]*?(?=<\/style>)/i,
            lookbehind: !0,
            inside: Prism.languages.css,
            alias: "language-css",
            greedy: !0
        }
    }), Prism.languages.insertBefore("inside", "attr-value", {
        "style-attr": {
            pattern: /\s*style=("|')(?:\\[\s\S]|(?!\1)[^\\])*\1/i,
            inside: {
                "attr-name": {
                    pattern: /^\s*style/i,
                    inside: Prism.languages.markup.tag.inside
                },
                punctuation: /^\s*=\s*['"]|['"]\s*$/,
                "attr-value": {
                    pattern: /.+/i,
                    inside: Prism.languages.css
                }
            },
            alias: "language-css"
        }
    }, Prism.languages.markup.tag)), Prism.languages.clike = {
        comment: [{
            pattern: /(^|[^\\])\/\*[\s\S]*?(?:\*\/|$)/,
            lookbehind: !0
        }, {
            pattern: /(^|[^\\:])\/\/.*/,
            lookbehind: !0
        }],
        string: {
            pattern: /(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
            greedy: !0
        },
        "class-name": {
            pattern: /((?:\b(?:class|interface|extends|implements|trait|instanceof|new)\s+)|(?:catch\s+\())[\w.\\]+/i,
            lookbehind: !0,
            inside: {
                punctuation: /[.\\]/
            }
        },
        keyword: /\b(?:if|else|while|do|for|return|in|instanceof|function|new|try|throw|catch|finally|null|break|continue)\b/,
        boolean: /\b(?:true|false)\b/,
        function: /[a-z0-9_]+(?=\()/i,
        number: /\b0x[\da-f]+\b|(?:\b\d+\.?\d*|\B\.\d+)(?:e[+-]?\d+)?/i,
        operator: /--?|\+\+?|!=?=?|<=?|>=?|==?=?|&&?|\|\|?|\?|\*|\/|~|\^|%/,
        punctuation: /[{}[\];(),.:]/
    }, Prism.languages.javascript = Prism.languages.extend("clike", {
        keyword: /\b(?:as|async|await|break|case|catch|class|const|continue|debugger|default|delete|do|else|enum|export|extends|finally|for|from|function|get|if|implements|import|in|instanceof|interface|let|new|null|of|package|private|protected|public|return|set|static|super|switch|this|throw|try|typeof|var|void|while|with|yield)\b/,
        number: /\b(?:0[xX][\dA-Fa-f]+|0[bB][01]+|0[oO][0-7]+|NaN|Infinity)\b|(?:\b\d+\.?\d*|\B\.\d+)(?:[Ee][+-]?\d+)?/,
        function: /[_$a-z\xA0-\uFFFF][$\w\xA0-\uFFFF]*(?=\s*\()/i,
        operator: /-[-=]?|\+[+=]?|!=?=?|<<?=?|>>?>?=?|=(?:==?|>)?|&[&=]?|\|[|=]?|\*\*?=?|\/=?|~|\^=?|%=?|\?|\.{3}/
    }), Prism.languages.insertBefore("javascript", "keyword", {
        regex: {
            pattern: /(^|[^\/])\/(?!\/)(\[[^\]\r\n]+]|\\.|[^\/\\\[\r\n])+\/[gimyu]{0,5}(?=\s*($|[\r\n,.;})]))/,
            lookbehind: !0,
            greedy: !0
        },
        "function-variable": {
            pattern: /[_$a-z\xA0-\uFFFF][$\w\xA0-\uFFFF]*(?=\s*=\s*(?:function\b|(?:\([^()]*\)|[_$a-z\xA0-\uFFFF][$\w\xA0-\uFFFF]*)\s*=>))/i,
            alias: "function"
        }
    }), Prism.languages.insertBefore("javascript", "string", {
        "template-string": {
            pattern: /`(?:\\[\s\S]|[^\\`])*`/,
            greedy: !0,
            inside: {
                interpolation: {
                    pattern: /\$\{[^}]+\}/,
                    inside: {
                        "interpolation-punctuation": {
                            pattern: /^\$\{|\}$/,
                            alias: "punctuation"
                        },
                        rest: Prism.languages.javascript
                    }
                },
                string: /[\s\S]+/
            }
        }
    }), Prism.languages.markup && Prism.languages.insertBefore("markup", "tag", {
        script: {
            pattern: /(<script[\s\S]*?>)[\s\S]*?(?=<\/script>)/i,
            lookbehind: !0,
            inside: Prism.languages.javascript,
            alias: "language-javascript",
            greedy: !0
        }
    }), Prism.languages.js = Prism.languages.javascript,
    function() {
        "undefined" != typeof self && self.Prism && self.document && document.querySelector && (self.Prism.fileHighlight = function() {
            var e = {
                js: "javascript",
                py: "python",
                rb: "ruby",
                ps1: "powershell",
                psm1: "powershell",
                sh: "bash",
                bat: "batch",
                h: "c",
                tex: "latex"
            };
            Array.prototype.slice.call(document.querySelectorAll("pre[data-src]")).forEach(function(t) {
                for (var n, a = t.getAttribute("data-src"), i = t, s = /\blang(?:uage)?-(?!\*)(\w+)\b/i; i && !s.test(i.className);) i = i.parentNode;
                if (i && (n = (t.className.match(s) || [, ""])[1]), !n) {
                    var r = (a.match(/\.(\w+)$/) || [, ""])[1];
                    n = e[r] || r
                }
                var o = document.createElement("code");
                o.className = "language-" + n, t.textContent = "", o.textContent = "Loading…", t.appendChild(o);
                var l = new XMLHttpRequest;
                l.open("GET", a, !0), l.onreadystatechange = function() {
                    4 == l.readyState && (l.status < 400 && l.responseText ? (o.textContent = l.responseText, Prism.highlightElement(o)) : l.status >= 400 ? o.textContent = "✖ Error " + l.status + " while fetching file: " + l.statusText : o.textContent = "✖ Error: File does not exist or is empty")
                }, l.send(null)
            })
        }, document.addEventListener("DOMContentLoaded", self.Prism.fileHighlight))
    }(),
    function(e) {
        var t = {
            variable: [{
                pattern: /\$?\(\([\s\S]+?\)\)/,
                inside: {
                    variable: [{
                        pattern: /(^\$\(\([\s\S]+)\)\)/,
                        lookbehind: !0
                    }, /^\$\(\(/],
                    number: /\b0x[\dA-Fa-f]+\b|(?:\b\d+\.?\d*|\B\.\d+)(?:[Ee]-?\d+)?/,
                    operator: /--?|-=|\+\+?|\+=|!=?|~|\*\*?|\*=|\/=?|%=?|<<=?|>>=?|<=?|>=?|==?|&&?|&=|\^=?|\|\|?|\|=|\?|:/,
                    punctuation: /\(\(?|\)\)?|,|;/
                }
            }, {
                pattern: /\$\([^)]+\)|`[^`]+`/,
                greedy: !0,
                inside: {
                    variable: /^\$\(|^`|\)$|`$/
                }
            }, /\$(?:[\w#?*!@]+|\{[^}]+\})/i]
        };
        e.languages.bash = {
            shebang: {
                pattern: /^#!\s*\/bin\/bash|^#!\s*\/bin\/sh/,
                alias: "important"
            },
            comment: {
                pattern: /(^|[^"{\\])#.*/,
                lookbehind: !0
            },
            string: [{
                pattern: /((?:^|[^<])<<\s*)["']?(\w+?)["']?\s*\r?\n(?:[\s\S])*?\r?\n\2/,
                lookbehind: !0,
                greedy: !0,
                inside: t
            }, {
                pattern: /(["'])(?:\\[\s\S]|\$\([^)]+\)|`[^`]+`|(?!\1)[^\\])*\1/,
                greedy: !0,
                inside: t
            }],
            variable: t.variable,
            function: {
                pattern: /(^|[\s;|&])(?:alias|apropos|apt-get|aptitude|aspell|awk|basename|bash|bc|bg|builtin|bzip2|cal|cat|cd|cfdisk|chgrp|chmod|chown|chroot|chkconfig|cksum|clear|cmp|comm|command|cp|cron|crontab|csplit|cut|date|dc|dd|ddrescue|df|diff|diff3|dig|dir|dircolors|dirname|dirs|dmesg|du|egrep|eject|enable|env|ethtool|eval|exec|expand|expect|export|expr|fdformat|fdisk|fg|fgrep|file|find|fmt|fold|format|free|fsck|ftp|fuser|gawk|getopts|git|grep|groupadd|groupdel|groupmod|groups|gzip|hash|head|help|hg|history|hostname|htop|iconv|id|ifconfig|ifdown|ifup|import|install|jobs|join|kill|killall|less|link|ln|locate|logname|logout|look|lpc|lpr|lprint|lprintd|lprintq|lprm|ls|lsof|make|man|mkdir|mkfifo|mkisofs|mknod|more|most|mount|mtools|mtr|mv|mmv|nano|netstat|nice|nl|nohup|notify-send|npm|nslookup|open|op|passwd|paste|pathchk|ping|pkill|popd|pr|printcap|printenv|printf|ps|pushd|pv|pwd|quota|quotacheck|quotactl|ram|rar|rcp|read|readarray|readonly|reboot|rename|renice|remsync|rev|rm|rmdir|rsync|screen|scp|sdiff|sed|seq|service|sftp|shift|shopt|shutdown|sleep|slocate|sort|source|split|ssh|stat|strace|su|sudo|sum|suspend|sync|tail|tar|tee|test|time|timeout|times|touch|top|traceroute|trap|tr|tsort|tty|type|ulimit|umask|umount|unalias|uname|unexpand|uniq|units|unrar|unshar|uptime|useradd|userdel|usermod|users|uuencode|uudecode|v|vdir|vi|vmstat|wait|watch|wc|wget|whereis|which|who|whoami|write|xargs|xdg-open|yes|zip)(?=$|[\s;|&])/,
                lookbehind: !0
            },
            keyword: {
                pattern: /(^|[\s;|&])(?:let|:|\.|if|then|else|elif|fi|for|break|continue|while|in|case|function|select|do|done|until|echo|exit|return|set|declare)(?=$|[\s;|&])/,
                lookbehind: !0
            },
            boolean: {
                pattern: /(^|[\s;|&])(?:true|false)(?=$|[\s;|&])/,
                lookbehind: !0
            },
            operator: /&&?|\|\|?|==?|!=?|<<<?|>>|<=?|>=?|=~/,
            punctuation: /\$?\(\(?|\)\)?|\.\.|[{}[\];]/
        };
        var n = t.variable[1].inside;
        n.string = e.languages.bash.string, n["function"] = e.languages.bash["function"], n.keyword = e.languages.bash.keyword, n["boolean"] = e.languages.bash["boolean"], n.operator = e.languages.bash.operator, n.punctuation = e.languages.bash.punctuation, e.languages.shell = e.languages.bash
    }(Prism), Prism.languages.csharp = Prism.languages.extend("clike", {
        keyword: /\b(?:abstract|add|alias|as|ascending|async|await|base|bool|break|byte|case|catch|char|checked|class|const|continue|decimal|default|delegate|descending|do|double|dynamic|else|enum|event|explicit|extern|false|finally|fixed|float|for|foreach|from|get|global|goto|group|if|implicit|in|int|interface|internal|into|is|join|let|lock|long|namespace|new|null|object|operator|orderby|out|override|params|partial|private|protected|public|readonly|ref|remove|return|sbyte|sealed|select|set|short|sizeof|stackalloc|static|string|struct|switch|this|throw|true|try|typeof|uint|ulong|unchecked|unsafe|ushort|using|value|var|virtual|void|volatile|where|while|yield)\b/,
        string: [{
            pattern: /@("|')(?:\1\1|\\[\s\S]|(?!\1)[^\\])*\1/,
            greedy: !0
        }, {
            pattern: /("|')(?:\\.|(?!\1)[^\\\r\n])*?\1/,
            greedy: !0
        }],
        number: /\b0x[\da-f]+\b|(?:\b\d+\.?\d*|\B\.\d+)f?/i
    }), Prism.languages.insertBefore("csharp", "keyword", {
        "generic-method": {
            pattern: /[a-z0-9_]+\s*<[^>\r\n]+?>\s*(?=\()/i,
            alias: "function",
            inside: {
                keyword: Prism.languages.csharp.keyword,
                punctuation: /[<>(),.:]/
            }
        },
        preprocessor: {
            pattern: /(^\s*)#.*/m,
            lookbehind: !0,
            alias: "property",
            inside: {
                directive: {
                    pattern: /(\s*#)\b(?:define|elif|else|endif|endregion|error|if|line|pragma|region|undef|warning)\b/,
                    lookbehind: !0,
                    alias: "keyword"
                }
            }
        }
    }), Prism.languages.dotnet = Prism.languages.csharp, Prism.languages.java = Prism.languages.extend("clike", {
        keyword: /\b(?:abstract|continue|for|new|switch|assert|default|goto|package|synchronized|boolean|do|if|private|this|break|double|implements|protected|throw|byte|else|import|public|throws|case|enum|instanceof|return|transient|catch|extends|int|short|try|char|final|interface|static|void|class|finally|long|strictfp|volatile|const|float|native|super|while)\b/,
        number: /\b0b[01]+\b|\b0x[\da-f]*\.?[\da-fp-]+\b|(?:\b\d+\.?\d*|\B\.\d+)(?:e[+-]?\d+)?[df]?/i,
        operator: {
            pattern: /(^|[^.])(?:\+[+=]?|-[-=]?|!=?|<<?=?|>>?>?=?|==?|&[&=]?|\|[|=]?|\*=?|\/=?|%=?|\^=?|[?:~])/m,
            lookbehind: !0
        }
    }), Prism.languages.insertBefore("java", "function", {
        annotation: {
            alias: "punctuation",
            pattern: /(^|[^.])@\w+/,
            lookbehind: !0
        }
    }), Prism.languages.python = {
        comment: {
            pattern: /(^|[^\\])#.*/,
            lookbehind: !0
        },
        "triple-quoted-string": {
            pattern: /("""|''')[\s\S]+?\1/,
            greedy: !0,
            alias: "string"
        },
        string: {
            pattern: /("|')(?:\\.|(?!\1)[^\\\r\n])*\1/,
            greedy: !0
        },
        function: {
            pattern: /((?:^|\s)def[ \t]+)[a-zA-Z_]\w*(?=\s*\()/g,
            lookbehind: !0
        },
        "class-name": {
            pattern: /(\bclass\s+)\w+/i,
            lookbehind: !0
        },
        keyword: /\b(?:as|assert|async|await|break|class|continue|def|del|elif|else|except|exec|finally|for|from|global|if|import|in|is|lambda|nonlocal|pass|print|raise|return|try|while|with|yield)\b/,
        builtin: /\b(?:__import__|abs|all|any|apply|ascii|basestring|bin|bool|buffer|bytearray|bytes|callable|chr|classmethod|cmp|coerce|compile|complex|delattr|dict|dir|divmod|enumerate|eval|execfile|file|filter|float|format|frozenset|getattr|globals|hasattr|hash|help|hex|id|input|int|intern|isinstance|issubclass|iter|len|list|locals|long|map|max|memoryview|min|next|object|oct|open|ord|pow|property|range|raw_input|reduce|reload|repr|reversed|round|set|setattr|slice|sorted|staticmethod|str|sum|super|tuple|type|unichr|unicode|vars|xrange|zip)\b/,
        boolean: /\b(?:True|False|None)\b/,
        number: /(?:\b(?=\d)|\B(?=\.))(?:0[bo])?(?:(?:\d|0x[\da-f])[\da-f]*\.?\d*|\.\d+)(?:e[+-]?\d+)?j?\b/i,
        operator: /[-+%=]=?|!=|\*\*?=?|\/\/?=?|<[<=>]?|>[=>]?|[&|^~]|\b(?:or|and|not)\b/,
        punctuation: /[{}[\];(),.:]/
    },
    function(e) {
        e.languages.php = e.languages.extend("clike", {
            keyword: /\b(?:and|or|xor|array|as|break|case|cfunction|class|const|continue|declare|default|die|do|else|elseif|enddeclare|endfor|endforeach|endif|endswitch|endwhile|extends|for|foreach|function|include|include_once|global|if|new|return|static|switch|use|require|require_once|var|while|abstract|interface|public|implements|private|protected|parent|throw|null|echo|print|trait|namespace|final|yield|goto|instanceof|finally|try|catch)\b/i,
            constant: /\b[A-Z0-9_]{2,}\b/,
            comment: {
                pattern: /(^|[^\\])(?:\/\*[\s\S]*?\*\/|\/\/.*)/,
                lookbehind: !0
            }
        }), e.languages.insertBefore("php", "string", {
            "shell-comment": {
                pattern: /(^|[^\\])#.*/,
                lookbehind: !0,
                alias: "comment"
            }
        }), e.languages.insertBefore("php", "keyword", {
            delimiter: {
                pattern: /\?>|<\?(?:php|=)?/i,
                alias: "important"
            },
            variable: /\$+(?:\w+\b|(?={))/i,
            package: {
                pattern: /(\\|namespace\s+|use\s+)[\w\\]+/,
                lookbehind: !0,
                inside: {
                    punctuation: /\\/
                }
            }
        }), e.languages.insertBefore("php", "operator", {
            property: {
                pattern: /(->)[\w]+/,
                lookbehind: !0
            }
        }), e.languages.insertBefore("php", "string", {
            "nowdoc-string": {
                pattern: /<<<'([^']+)'(?:\r\n?|\n)(?:.*(?:\r\n?|\n))*?\1;/,
                greedy: !0,
                alias: "string",
                inside: {
                    delimiter: {
                        pattern: /^<<<'[^']+'|[a-z_]\w*;$/i,
                        alias: "symbol",
                        inside: {
                            punctuation: /^<<<'?|[';]$/
                        }
                    }
                }
            },
            "heredoc-string": {
                pattern: /<<<(?:"([^"]+)"(?:\r\n?|\n)(?:.*(?:\r\n?|\n))*?\1;|([a-z_]\w*)(?:\r\n?|\n)(?:.*(?:\r\n?|\n))*?\2;)/i,
                greedy: !0,
                alias: "string",
                inside: {
                    delimiter: {
                        pattern: /^<<<(?:"[^"]+"|[a-z_]\w*)|[a-z_]\w*;$/i,
                        alias: "symbol",
                        inside: {
                            punctuation: /^<<<"?|[";]$/
                        }
                    },
                    interpolation: null
                }
            },
            "single-quoted-string": {
                pattern: /'(?:\\[\s\S]|[^\\'])*'/,
                greedy: !0,
                alias: "string"
            },
            "double-quoted-string": {
                pattern: /"(?:\\[\s\S]|[^\\"])*"/,
                greedy: !0,
                alias: "string",
                inside: {
                    interpolation: null
                }
            }
        }), delete e.languages.php.string;
        var t = {
            pattern: /{\$(?:{(?:{[^{}]+}|[^{}]+)}|[^{}])+}|(^|[^\\{])\$+(?:\w+(?:\[.+?]|->\w+)*)/,
            lookbehind: !0,
            inside: {
                rest: e.languages.php
            }
        };
        e.languages.php["heredoc-string"].inside.interpolation = t, e.languages.php["double-quoted-string"].inside.interpolation = t, e.languages.markup && (e.hooks.add("before-highlight", function(t) {
            "php" === t.language && /(?:<\?php|<\?)/gi.test(t.code) && (t.tokenStack = [], t.backupCode = t.code, t.code = t.code.replace(/(?:<\?php|<\?)[\s\S]*?(?:\?>|$)/gi, function(e) {
                for (var n = t.tokenStack.length; - 1 !== t.backupCode.indexOf("___PHP" + n + "___");) ++n;
                return t.tokenStack[n] = e, "___PHP" + n + "___"
            }), t.grammar = e.languages.markup)
        }), e.hooks.add("before-insert", function(e) {
            "php" === e.language && e.backupCode && (e.code = e.backupCode, delete e.backupCode)
        }), e.hooks.add("after-highlight", function(t) {
            if ("php" === t.language && t.tokenStack) {
                t.grammar = e.languages.php;
                for (var n = 0, a = Object.keys(t.tokenStack); n < a.length; ++n) {
                    var i = a[n],
                        s = t.tokenStack[i];
                    t.highlightedCode = t.highlightedCode.replace("___PHP" + i + "___", '<span class="token php language-php">' + e.highlight(s, t.grammar, "php").replace(/\$/g, "$$$$") + "</span>")
                }
                t.element.innerHTML = t.highlightedCode
            }
        }))
    }(Prism),
    function(e) {
        e.languages.ruby = e.languages.extend("clike", {
            comment: [/#(?!\{[^\r\n]*?\}).*/, /^=begin(?:\r?\n|\r)(?:.*(?:\r?\n|\r))*?=end/m],
            keyword: /\b(?:alias|and|BEGIN|begin|break|case|class|def|define_method|defined|do|each|else|elsif|END|end|ensure|false|for|if|in|module|new|next|nil|not|or|protected|private|public|raise|redo|require|rescue|retry|return|self|super|then|throw|true|undef|unless|until|when|while|yield)\b/
        });
        var t = {
            pattern: /#\{[^}]+\}/,
            inside: {
                delimiter: {
                    pattern: /^#\{|\}$/,
                    alias: "tag"
                },
                rest: e.languages.ruby
            }
        };
        e.languages.insertBefore("ruby", "keyword", {
            regex: [{
                pattern: /%r([^a-zA-Z0-9\s{(\[<])(?:(?!\1)[^\\]|\\[\s\S])*\1[gim]{0,3}/,
                greedy: !0,
                inside: {
                    interpolation: t
                }
            }, {
                pattern: /%r\((?:[^()\\]|\\[\s\S])*\)[gim]{0,3}/,
                greedy: !0,
                inside: {
                    interpolation: t
                }
            }, {
                pattern: /%r\{(?:[^#{}\\]|#(?:\{[^}]+\})?|\\[\s\S])*\}[gim]{0,3}/,
                greedy: !0,
                inside: {
                    interpolation: t
                }
            }, {
                pattern: /%r\[(?:[^\[\]\\]|\\[\s\S])*\][gim]{0,3}/,
                greedy: !0,
                inside: {
                    interpolation: t
                }
            }, {
                pattern: /%r<(?:[^<>\\]|\\[\s\S])*>[gim]{0,3}/,
                greedy: !0,
                inside: {
                    interpolation: t
                }
            }, {
                pattern: /(^|[^\/])\/(?!\/)(\[.+?]|\\.|[^\/\\\r\n])+\/[gim]{0,3}(?=\s*($|[\r\n,.;})]))/,
                lookbehind: !0,
                greedy: !0
            }],
            variable: /[@$]+[a-zA-Z_]\w*(?:[?!]|\b)/,
            symbol: {
                pattern: /(^|[^:]):[a-zA-Z_]\w*(?:[?!]|\b)/,
                lookbehind: !0
            }
        }), e.languages.insertBefore("ruby", "number", {
            builtin: /\b(?:Array|Bignum|Binding|Class|Continuation|Dir|Exception|FalseClass|File|Stat|Fixnum|Float|Hash|Integer|IO|MatchData|Method|Module|NilClass|Numeric|Object|Proc|Range|Regexp|String|Struct|TMS|Symbol|ThreadGroup|Thread|Time|TrueClass)\b/,
            constant: /\b[A-Z]\w*(?:[?!]|\b)/
        }), e.languages.ruby.string = [{
            pattern: /%[qQiIwWxs]?([^a-zA-Z0-9\s{(\[<])(?:(?!\1)[^\\]|\\[\s\S])*\1/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }, {
            pattern: /%[qQiIwWxs]?\((?:[^()\\]|\\[\s\S])*\)/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }, {
            pattern: /%[qQiIwWxs]?\{(?:[^#{}\\]|#(?:\{[^}]+\})?|\\[\s\S])*\}/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }, {
            pattern: /%[qQiIwWxs]?\[(?:[^\[\]\\]|\\[\s\S])*\]/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }, {
            pattern: /%[qQiIwWxs]?<(?:[^<>\\]|\\[\s\S])*>/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }, {
            pattern: /("|')(?:#\{[^}]+\}|\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
            greedy: !0,
            inside: {
                interpolation: t
            }
        }]
    }(Prism),
    function(e) {
        "function" == typeof define && define.amd ? define(["jquery"], e) : e(jQuery)
    }(function(e) {
        "use strict";

        function t(e, t, n, a) {
            var i, s = {
                raw: {}
            };
            a = a || {};
            for (i in a) a.hasOwnProperty(i) && ("classes" === e ? (s.raw[a[i]] = t + "-" + a[i], s[a[i]] = "." + t + "-" + a[i]) : (s.raw[i] = a[i], s[i] = a[i] + "." + t));
            for (i in n) n.hasOwnProperty(i) && ("classes" === e ? (s.raw[i] = n[i].replace(/{ns}/g, t), s[i] = n[i].replace(/{ns}/g, "." + t)) : (s.raw[i] = n[i].replace(/.{ns}/g, ""), s[i] = n[i].replace(/{ns}/g, t)));
            return s
        }

        function n() {
            var e, t = {
                    WebkitTransition: "webkitTransitionEnd",
                    MozTransition: "transitionend",
                    OTransition: "otransitionend",
                    transition: "transitionend"
                },
                n = ["transition", "-webkit-transition"],
                a = {
                    transform: "transform",
                    MozTransform: "-moz-transform",
                    OTransform: "-o-transform",
                    msTransform: "-ms-transform",
                    webkitTransform: "-webkit-transform"
                },
                i = "transitionend",
                s = "",
                r = "",
                o = document.createElement("div");
            for (e in t)
                if (t.hasOwnProperty(e) && e in o.style) {
                    i = t[e], f.support.transition = !0;
                    break
                } b.transitionEnd = i + ".{ns}";
            for (e in n)
                if (n.hasOwnProperty(e) && n[e] in o.style) {
                    s = n[e];
                    break
                } f.transition = s;
            for (e in a)
                if (a.hasOwnProperty(e) && a[e] in o.style) {
                    f.support.transform = !0, r = a[e];
                    break
                } f.transform = r
        }

        function a() {
            f.windowWidth = f.$window.width(), f.windowHeight = f.$window.height(), v = h.startTimer(v, y, i)
        }

        function i() {
            for (var e in f.ResizeHandlers) f.ResizeHandlers.hasOwnProperty(e) && f.ResizeHandlers[e].callback.call(window, f.windowWidth, f.windowHeight)
        }

        function s() {
            if (f.support.raf) {
                f.window.requestAnimationFrame(s);
                for (var e in f.RAFHandlers) f.RAFHandlers.hasOwnProperty(e) && f.RAFHandlers[e].callback.call(window)
            }
        }

        function r(e, t) {
            return parseInt(e.priority) - parseInt(t.priority)
        }
        var o, l, u, c = "undefined" != typeof window ? window : this,
            d = c.document,
            p = function() {
                this.Version = "@version", this.Plugins = {}, this.DontConflict = !1, this.Conflicts = {
                    fn: {}
                }, this.ResizeHandlers = [], this.RAFHandlers = [], this.window = c, this.$window = e(c), this.document = d, this.$document = e(d), this.$body = null, this.windowWidth = 0, this.windowHeight = 0, this.fallbackWidth = 1024, this.fallbackHeight = 768, this.userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera, this.isFirefox = /Firefox/i.test(this.userAgent), this.isChrome = /Chrome/i.test(this.userAgent), this.isSafari = /Safari/i.test(this.userAgent) && !this.isChrome, this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(this.userAgent), this.isIEMobile = /IEMobile/i.test(this.userAgent), this.isFirefoxMobile = this.isFirefox && this.isMobile, this.transform = null, this.transition = null, this.support = {
                    file: !!(window.File && window.FileList && window.FileReader),
                    history: !!(window.history && window.history.pushState && window.history.replaceState),
                    matchMedia: !(!window.matchMedia && !window.msMatchMedia),
                    pointer: !!window.PointerEvent,
                    raf: !(!window.requestAnimationFrame || !window.cancelAnimationFrame),
                    touch: !!("ontouchstart" in window || window.DocumentTouch && document instanceof window.DocumentTouch),
                    transition: !1,
                    transform: !1
                }
            },
            h = {
                killEvent: function(e, t) {
                    try {
                        e.preventDefault(), e.stopPropagation(), t && e.stopImmediatePropagation()
                    } catch (e) {}
                },
                killGesture: function(e) {
                    try {
                        e.preventDefault()
                    } catch (e) {}
                },
                lockViewport: function(t) {
                    w[t] = !0, e.isEmptyObject(w) || k || (o.length ? o.attr("content", u) : o = e("head").append('<meta name="viewport" content="' + u + '">'), f.$body.on(b.gestureChange, h.killGesture).on(b.gestureStart, h.killGesture).on(b.gestureEnd, h.killGesture), k = !0)
                },
                unlockViewport: function(t) {
                    "undefined" !== e.type(w[t]) && delete w[t], e.isEmptyObject(w) && k && (o.length && (l ? o.attr("content", l) : o.remove()), f.$body.off(b.gestureChange).off(b.gestureStart).off(b.gestureEnd), k = !1)
                },
                startTimer: function(e, t, n, a) {
                    return h.clearTimer(e), a ? setInterval(n, t) : setTimeout(n, t)
                },
                clearTimer: function(e, t) {
                    e && (t ? clearInterval(e) : clearTimeout(e), e = null)
                },
                sortAsc: function(e, t) {
                    return parseInt(e, 10) - parseInt(t, 10)
                },
                sortDesc: function(e, t) {
                    return parseInt(t, 10) - parseInt(e, 10)
                },
                decodeEntities: function(e) {
                    var t = f.document.createElement("textarea");
                    return t.innerHTML = e, t.value
                },
                parseQueryString: function(e) {
                    for (var t = {}, n = e.slice(e.indexOf("?") + 1).split("&"), a = 0; a < n.length; a++) {
                        var i = n[a].split("=");
                        t[i[0]] = i[1]
                    }
                    return t
                }
            },
            f = new p,
            g = e.Deferred(),
            m = {
                base: "{ns}",
                element: "{ns}-element"
            },
            b = {
                namespace: ".{ns}",
                beforeUnload: "beforeunload.{ns}",
                blur: "blur.{ns}",
                change: "change.{ns}",
                click: "click.{ns}",
                dblClick: "dblclick.{ns}",
                drag: "drag.{ns}",
                dragEnd: "dragend.{ns}",
                dragEnter: "dragenter.{ns}",
                dragLeave: "dragleave.{ns}",
                dragOver: "dragover.{ns}",
                dragStart: "dragstart.{ns}",
                drop: "drop.{ns}",
                error: "error.{ns}",
                focus: "focus.{ns}",
                focusIn: "focusin.{ns}",
                focusOut: "focusout.{ns}",
                gestureChange: "gesturechange.{ns}",
                gestureStart: "gesturestart.{ns}",
                gestureEnd: "gestureend.{ns}",
                input: "input.{ns}",
                keyDown: "keydown.{ns}",
                keyPress: "keypress.{ns}",
                keyUp: "keyup.{ns}",
                load: "load.{ns}",
                mouseDown: "mousedown.{ns}",
                mouseEnter: "mouseenter.{ns}",
                mouseLeave: "mouseleave.{ns}",
                mouseMove: "mousemove.{ns}",
                mouseOut: "mouseout.{ns}",
                mouseOver: "mouseover.{ns}",
                mouseUp: "mouseup.{ns}",
                panStart: "panstart.{ns}",
                pan: "pan.{ns}",
                panEnd: "panend.{ns}",
                resize: "resize.{ns}",
                scaleStart: "scalestart.{ns}",
                scaleEnd: "scaleend.{ns}",
                scale: "scale.{ns}",
                scroll: "scroll.{ns}",
                select: "select.{ns}",
                swipe: "swipe.{ns}",
                touchCancel: "touchcancel.{ns}",
                touchEnd: "touchend.{ns}",
                touchLeave: "touchleave.{ns}",
                touchMove: "touchmove.{ns}",
                touchStart: "touchstart.{ns}"
            },
            v = null,
            y = 20,
            w = [],
            k = !1;
        return p.prototype.NoConflict = function() {
            f.DontConflict = !0;
            for (var t in f.Plugins) f.Plugins.hasOwnProperty(t) && (e[t] = f.Conflicts[t], e.fn[t] = f.Conflicts.fn[t])
        }, p.prototype.Ready = function(e) {
            "complete" === f.document.readyState || "loading" !== f.document.readyState && !f.document.documentElement.doScroll ? e() : f.document.addEventListener("DOMContentLoaded", e)
        }, p.prototype.Plugin = function(n, a) {
            return f.Plugins[n] = function(n, a) {
                function i(t) {
                    var i, s, r, l = "object" === e.type(t),
                        u = Array.prototype.slice.call(arguments, l ? 1 : 0),
                        c = this,
                        d = e();
                    for (t = e.extend(!0, {}, a.defaults || {}, l ? t : {}), s = 0, r = c.length; s < r; s++)
                        if (i = c.eq(s), !o(i)) {
                            a.guid++;
                            var p = "__" + a.guid,
                                h = a.classes.raw.base + p,
                                f = i.data(n + "-options"),
                                m = e.extend(!0, {
                                    $el: i,
                                    guid: p,
                                    numGuid: a.guid,
                                    rawGuid: h,
                                    dotGuid: "." + h
                                }, t, "object" === e.type(f) ? f : {});
                            i.addClass(a.classes.raw.element).data(g, m), a.methods._construct.apply(i, [m].concat(u)), d = d.add(i)
                        } for (s = 0, r = d.length; s < r; s++) i = d.eq(s), a.methods._postConstruct.apply(i, [o(i)]);
                    return c
                }

                function s() {
                    a.functions.iterate.apply(this, [a.methods._destruct].concat(Array.prototype.slice.call(arguments, 1))), this.removeClass(a.classes.raw.element).removeData(g)
                }

                function o(e) {
                    return e.data(g)
                }

                function l(t) {
                    if (this instanceof e) {
                        var n = a.methods[t];
                        if ("object" === e.type(t) || !t) return i.apply(this, arguments);
                        if (n && 0 !== t.indexOf("_")) {
                            var s = [n].concat(Array.prototype.slice.call(arguments, 1));
                            return a.functions.iterate.apply(this, s)
                        }
                        return this
                    }
                }

                function u(t) {
                    var n = a.utilities[t] || a.utilities._initialize || !1;
                    if (n) {
                        var i = Array.prototype.slice.call(arguments, "object" === e.type(t) ? 0 : 1);
                        return n.apply(window, i)
                    }
                }

                function c(t) {
                    a.defaults = e.extend(!0, a.defaults, t || {})
                }

                function d(t) {
                    for (var n = this, a = Array.prototype.slice.call(arguments, 1), i = 0, s = n.length; i < s; i++) {
                        var r = n.eq(i),
                            l = o(r) || {};
                        "undefined" !== e.type(l.$el) && t.apply(r, [l].concat(a))
                    }
                    return n
                }
                var p = "fs-" + n,
                    g = "fs" + n.replace(/(^|\s)([a-z])/g, function(e, t, n) {
                        return t + n.toUpperCase()
                    });
                return a.initialized = !1, a.priority = a.priority || 10, a.classes = t("classes", p, m, a.classes), a.events = t("events", n, b, a.events), a.functions = e.extend({
                    getData: o,
                    iterate: d
                }, h, a.functions), a.methods = e.extend(!0, {
                    _construct: e.noop,
                    _postConstruct: e.noop,
                    _destruct: e.noop,
                    _resize: !1,
                    destroy: s
                }, a.methods), a.utilities = e.extend(!0, {
                    _initialize: !1,
                    _delegate: !1,
                    defaults: c
                }, a.utilities), a.widget && (f.Conflicts.fn[n] = e.fn[n], e.fn[g] = l, f.DontConflict || (e.fn[n] = e.fn[g])), f.Conflicts[n] = e[n], e[g] = a.utilities._delegate || u, f.DontConflict || (e[n] = e[g]), a.namespace = n, a.namespaceClean = g, a.guid = 0, a.methods._resize && (f.ResizeHandlers.push({
                    namespace: n,
                    priority: a.priority,
                    callback: a.methods._resize
                }), f.ResizeHandlers.sort(r)), a.methods._raf && (f.RAFHandlers.push({
                    namespace: n,
                    priority: a.priority,
                    callback: a.methods._raf
                }), f.RAFHandlers.sort(r)), a
            }(n, a), f.Plugins[n]
        }, f.$window.on("resize.fs", a), a(), s(), f.Ready(function() {
            f.$body = e("body"), e("html").addClass(f.support.touch ? "touchevents" : "no-touchevents"), o = e('meta[name="viewport"]'), l = !!o.length && o.attr("content"), u = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0", g.resolve()
        }), b.clickTouchStart = b.click + " " + b.touchStart, n(), window.Formstone = f, f
    }),
    function(e) {
        "function" == typeof define && define.amd ? define(["jquery", "./core", "./scrollbar", "./touch"], e) : e(jQuery, Formstone)
    }(function(e, t) {
        "use strict";

        function n() {
            j = t.$body
        }

        function a(n) {
            n.multiple = this.prop("multiple"), n.disabled = this.prop("disabled") || this.is("[readonly]"), n.lastIndex = !1, n["native"] = n.mobile || n["native"], n.useNative = n["native"] || t.isMobile, n.multiple ? n.links = !1 : n.external && (n.links = !0);
            var a = this.find("[selected]").not(":disabled"),
                i = this.find(":selected").not(":disabled"),
                s = i.text(),
                r = this.find("option").index(i);
            n.multiple || "" === n.label || a.length ? n.label = "" : (i = this.prepend('<option value="" class="' + T.item_placeholder + '" selected>' + n.label + "</option>"), s = n.label, r = 0);
            var o = this.find("option, optgroup"),
                l = o.filter("option"),
                d = e('[for="' + this.attr("id") + '"]');
            n.tabIndex = this[0].tabIndex, this[0].tabIndex = -1, d.length && (d[0].tabIndex = -1);
            var p = [T.base, n.theme, n.customClass];
            n.useNative ? p.push(T["native"]) : n.cover && p.push(T.cover), n.multiple && p.push(T.multiple), n.disabled && p.push(T.disabled), n.id = this.attr("id"), n.id ? n.ariaId = n.id : n.ariaId = n.rawGuid, n.ariaId += "-dropdown", n.selectedAriaId = n.ariaId + "-selected";
            var h = "",
                f = "";
            h += '<div class="' + p.join(" ") + '"id="' + n.ariaId + '" tabindex="' + n.tabIndex + '" role="listbox"', n.multiple ? h += ' aria-label="multi select"' : h += ' aria-haspopup="true" aria-live="polite" aria-labeledby="' + n.selectedAriaId + '"', h += "></div>", n.multiple || (f += '<button type="button" class="' + T.selected + '" id="' + n.selectedAriaId + '" tabindex="-1">', f += e("<span></span>").text(P(s, n.trim)).html(), f += "</button>"), f += '<div class="' + T.options + '">', f += "</div>", this.wrap(h).after(f), n.$dropdown = this.parent(_.base), n.$label = d, n.$allOptions = o, n.$options = l, n.$selected = n.$dropdown.find(_.selected), n.$wrapper = n.$dropdown.find(_.options), n.$placeholder = n.$dropdown.find(_.placeholder), n.index = -1, n.closed = !0, n.focused = !1, u(n), n.multiple || k(r, n), e.fn.fsScrollbar !== undefined && n.$wrapper.fsScrollbar({
                theme: n.theme
            }).find(".fs-scrollbar-content").attr("tabindex", null), n.$dropdown.on(I.click, n, c), n.$selected.on(I.click, n, c), n.$dropdown.on(I.click, _.item, n, m).on(I.close, n, g), this.on(I.change, n, b), n.useNative || (this.on(I.focusIn, n, function(e) {
                e.data.$dropdown.trigger(I.raw.focus)
            }), n.$dropdown.on(I.focusIn, n, v).on(I.focusOut, n, y))
        }

        function i(t) {
            t.$dropdown.hasClass(T.open) && t.$selected.trigger(I.click), e.fn.fsScrollbar !== undefined && t.$wrapper.fsScrollbar("destroy"), t.$el[0].tabIndex = t.tabIndex, t.$label.length && (t.$label[0].tabIndex = t.tabIndex), t.$dropdown.off(I.namespace), t.$options.off(I.namespace), t.$placeholder.remove(), t.$selected.remove(), t.$wrapper.remove(), t.$el.off(I.namespace).show().unwrap()
        }

        function s(e, t) {
            if (void 0 !== t) {
                var n = e.$items.index(e.$items.filter("[data-value=" + t + "]"));
                e.$items.eq(n).addClass(T.item_disabled), e.$options.eq(n).prop("disabled", !0)
            } else e.$dropdown.hasClass(T.open) && e.$selected.trigger(I.click), e.$dropdown.addClass(T.disabled), e.$el.prop("disabled", !0), e.disabled = !0
        }

        function r(e, t) {
            if (void 0 !== t) {
                var n = e.$items.index(e.$items.filter("[data-value=" + t + "]"));
                e.$items.eq(n).addClass(T.item_disabled), e.$options.eq(n).prop("readonly", !0)
            } else e.$dropdown.hasClass(T.open) && e.$selected.trigger(I.click), e.$dropdown.addClass(T.disabled), e.$el.prop("readonly", !0), e.disabled = !0
        }

        function o(e, t) {
            if (void 0 !== t) {
                var n = e.$items.index(e.$items.filter("[data-value=" + t + "]"));
                e.$items.eq(n).removeClass(T.item_disabled), e.$options.eq(n).prop("disabled", !1), e.$options.eq(n).prop("readonly", !1)
            } else e.$dropdown.removeClass(T.disabled), e.$el.prop("disabled", !1), e.$el.prop("readonly", !1), e.disabled = !1
        }

        function l(t) {
            e.fn.fsScrollbar !== undefined && t.$wrapper.fsScrollbar("destroy");
            var n = t.index;
            t.$allOptions = t.$el.find("option, optgroup"), t.$options = t.$allOptions.filter("option"), t.index = -1, n = t.$options.index(t.$options.filter(":selected")), u(t), t.multiple || k(n, t), e.fn.fsScrollbar !== undefined && t.$wrapper.fsScrollbar({
                theme: t.theme
            }).find(".fs-scrollbar-content").attr("tabindex", null)
        }

        function u(t) {
            for (var n = "", a = 0, i = 0, s = t.$allOptions.length; i < s; i++) {
                var r = t.$allOptions.eq(i),
                    o = [];
                if ("OPTGROUP" === r[0].tagName) o.push(T.group), r.prop("disabled") && o.push(T.disabled), n += '<span class="' + o.join(" ") + '">' + r.attr("label") + "</span>";
                else {
                    var l = r.val(),
                        u = r.data("label"),
                        c = t.links ? "a" : 'button type="button"';
                    if (r.attr("value") || r.attr("value", l), o.push(T.item), (r.hasClass(T.item_placeholder) || "" == r.val()) && (o.push(T.item_placeholder), c = "span"), r.prop("selected") && o.push(T.item_selected), r.prop("disabled") && o.push(T.item_disabled), n += "<" + c + ' class="' + o.join(" ") + '"', t.links ? "span" === c ? n += ' aria-hidden="true"' : (n += ' href="' + l + '"', t.external && (n += ' target="_blank"')) : n += ' data-value="' + l + '"', n += ' role="option"', r.prop("selected") && (n += ' "aria-selected"="true"'), n += ">", u) n += u;
                    else {
                        var d = r.data("html-label");
                        t.useNative || d == undefined ? n += M.decodeEntities(P(r.text(), t.trim)) : n += M.decodeEntities(P(d, t.trim))
                    }
                    n += "</" + c + ">", a++
                }
            }
            t.$items = t.$wrapper.html(e.parseHTML(n)).find(_.item)
        }

        function c(e) {
            M.killEvent(e);
            var t = e.data;
            t.disabled || t.useNative || (t.closed ? p(t) : h(t)), d(t)
        }

        function d(t) {
            e(_.base).not(t.$dropdown).trigger(I.close, [t])
        }

        function p(e) {
            if (e.closed) {
                var t = O.height(),
                    n = e.$wrapper.outerHeight(!0);
                e.$dropdown[0].getBoundingClientRect().bottom + n > t - e.bottomEdge && e.$dropdown.addClass(T.bottom), j.on(I.click + e.dotGuid, ":not(" + _.options + ")", e, f), e.$dropdown.trigger(I.focusIn), e.$dropdown.addClass(T.open), $(e), e.closed = !1
            }
        }

        function h(e) {
            e && !e.closed && (j.off(I.click + e.dotGuid), e.$dropdown.removeClass([T.open, T.bottom].join(" ")), e.closed = !0)
        }

        function f(t) {
            M.killEvent(t);
            var n = t.data;
            n && 0 === e(t.currentTarget).parents(_.base).length && (h(n), n.$dropdown.trigger(I.focusOut))
        }

        function g(e) {
            var t = e.data;
            t && (h(t), t.$dropdown.trigger(I.focusOut))
        }

        function m(t) {
            var n = e(this),
                a = t.data;
            if (M.killEvent(t), !a.disabled) {
                var i = a.$items.index(n);
                a.focusIndex = i, a.$wrapper.is(":visible") && (k(i, a, t.shiftKey, t.metaKey || t.ctrlKey), x(a)), a.multiple || h(a), a.$dropdown.trigger(I.focus)
            }
        }

        function b(t, n) {
            var a = (e(this), t.data);
            if (!n && !a.multiple) {
                var i = a.$options.index(a.$options.filter(":selected"));
                a.focusIndex = i, k(i, a), x(a, !0)
            }
        }

        function v(t) {
            M.killEvent(t);
            var n = (e(t.currentTarget), t.data);
            n.disabled || n.multiple || n.focused || (d(n), n.focused = !0, n.focusIndex = n.index, n.input = "", n.$dropdown.addClass(T.focus).on(I.keyDown + n.dotGuid, n, w))
        }

        function y(t) {
            M.killEvent(t);
            var n = (e(t.currentTarget), t.data);
            n.focused && n.closed && (n.focused = !1, n.$dropdown.removeClass(T.focus).off(I.keyDown + n.dotGuid), n.multiple || (h(n), n.index !== n.focusIndex && (x(n), n.focusIndex = n.index)))
        }

        function w(n) {
            var a = n.data;
            if (a.keyTimer = M.startTimer(a.keyTimer, 1e3, function() {
                    a.input = ""
                }), 13 === n.keyCode) a.closed || (h(a), k(a.index, a)), x(a);
            else if (!(9 === n.keyCode || n.metaKey || n.altKey || n.ctrlKey || n.shiftKey)) {
                M.killEvent(n);
                var i = a.$items.length - 1,
                    s = a.index < 0 ? 0 : a.index;
                if (e.inArray(n.keyCode, t.isFirefox ? [38, 40, 37, 39] : [38, 40]) > -1) s += 38 === n.keyCode || t.isFirefox && 37 === n.keyCode ? -1 : 1, s < 0 && (s = 0), s > i && (s = i);
                else {
                    var r, o = String.fromCharCode(n.keyCode).toUpperCase();
                    for (a.input += o, r = a.index + 1; r <= i; r++)
                        if (a.$options.eq(r).text().substr(0, a.input.length).toUpperCase() === a.input) {
                            s = r;
                            break
                        } if (s < 0 || s === a.index)
                        for (r = 0; r <= i; r++)
                            if (a.$options.eq(r).text().substr(0, a.input.length).toUpperCase() === a.input) {
                                s = r;
                                break
                            }
                }
                s >= 0 && (k(s, a), $(a))
            }
        }

        function k(e, t, n, a) {
            var i = t.$items.eq(e),
                s = t.$options.eq(e),
                r = i.hasClass(T.item_selected);
            if (!i.hasClass(T.item_disabled))
                if (t.multiple)
                    if (t.useNative) r ? (s.prop("selected", null).attr("aria-selected", null), i.removeClass(T.item_selected)) : (s.prop("selected", !0).attr("aria-selected", !0), i.addClass(T.item_selected));
                    else if (n && !1 !== t.lastIndex) {
                var o = t.lastIndex > e ? e : t.lastIndex,
                    l = (t.lastIndex > e ? t.lastIndex : e) + 1;
                t.$options.prop("selected", null).attr("aria-selected", null), t.$items.filter(_.item_selected).removeClass(T.item_selected), t.$options.slice(o, l).not("[disabled]").prop("selected", !0), t.$items.slice(o, l).not(_.item_disabled).addClass(T.item_selected)
            } else a || t.selectMultiple ? (r ? (s.prop("selected", null).attr("aria-selected", null), i.removeClass(T.item_selected)) : (s.prop("selected", !0).attr("aria-selected", !0), i.addClass(T.item_selected)), t.lastIndex = e) : (t.$options.prop("selected", null).attr("aria-selected", null), t.$items.filter(_.item_selected).removeClass(T.item_selected), s.prop("selected", !0).attr("aria-selected", !0), i.addClass(T.item_selected), t.lastIndex = e);
            else if (e > -1 && e < t.$items.length) {
                if (e !== t.index) {
                    var u = s.data("label") || i.html();
                    t.$selected.html(u).removeClass(_.item_placeholder), t.$items.filter(_.item_selected).removeClass(T.item_selected), t.$el[0].selectedIndex = e, i.addClass(T.item_selected), t.index = e
                }
            } else "" !== t.label && t.$selected.html(t.label)
        }

        function $(t) {
            var n = t.$items.eq(t.index),
                a = t.index >= 0 && !n.hasClass(T.item_placeholder) ? n.position() : {
                    left: 0,
                    top: 0
                },
                i = (t.$wrapper.outerHeight() - n.outerHeight()) / 2;
            e.fn.fsScrollbar !== undefined ? t.$wrapper.fsScrollbar("resize").fsScrollbar("scroll", t.$wrapper.find(".fs-scrollbar-content").scrollTop() + a.top) : t.$wrapper.scrollTop(t.$wrapper.scrollTop() + a.top - i)
        }

        function x(e, t) {
            e.links ? C(e) : t || e.$el.trigger(I.raw.change, [!0])
        }

        function C(e) {
            var t = e.$el.val();
            e.external ? E.open(t) : E.location.href = t
        }

        function P(e, t) {
            return 0 === t ? e : e.length > t ? e.substring(0, t) + "..." : e
        }
        var S = t.Plugin("dropdown", {
                widget: !0,
                defaults: {
                    bottomEdge: 0,
                    cover: !1,
                    customClass: "",
                    label: "",
                    external: !1,
                    links: !1,
                    mobile: !1,
                    native: !1,
                    theme: "",
                    trim: 0,
                    selectMultiple: !1
                },
                methods: {
                    _construct: a,
                    _destruct: i,
                    disable: s,
                    readonly: r,
                    enable: o,
                    update: l,
                    open: p,
                    close: h
                },
                classes: ["cover", "bottom", "multiple", "mobile", "native", "open", "disabled", "focus", "selected", "options", "group", "item", "item_disabled", "item_selected", "item_placeholder"],
                events: {
                    close: "close"
                }
            }),
            _ = S.classes,
            T = _.raw,
            I = S.events,
            M = S.functions,
            E = t.window,
            O = t.$window,
            j = (t.document, null);
        t.Ready(n)
    }),
    function(e) {
        "function" == typeof define && define.amd ? define(["jquery", "./core", "./touch"], e) : e(jQuery, Formstone)
    }(function(e, t) {
        "use strict";

        function n() {
            b = t.$body
        }

        function a() {
            $.iterate.call(x, l)
        }

        function i() {
            x = e(y.base)
        }

        function s(e) {
            var t = "";
            t += '<div class="' + w.bar + '">', t += '<div class="' + w.track + '">', t += '<button type="button" class="' + w.handle + '" aria-hidden="true" tabindex="-1"></button>', t += "</div></div>", e.paddingRight = parseInt(this.css("padding-right"), 10), e.paddingBottom = parseInt(this.css("padding-bottom"), 10), e.thisClasses = [w.base, e.theme, e.customClass, e.horizontal ? w.horizontal : ""], this.addClass(e.thisClasses.join(" ")).wrapInner('<div class="' + w.content + '" tabindex="0"></div>').prepend(t), e.$content = this.find(y.content), e.$bar = this.find(y.bar), e.$track = this.find(y.track), e.$handle = this.find(y.handle), e.trackMargin = parseInt(e.trackMargin, 10), e.$content.on(k.scroll, e, u), e.mouseWheel && e.$content.on("wheel" + k.namespace, e, d), e.$track.fsTouch({
                axis: e.horizontal ? "x" : "y",
                pan: !0
            }).on(k.panStart, e, h).on(k.pan, e, f).on(k.panEnd, e, g).on(k.click, $.killEvent).on("wheel" + k.namespace, e, c), l(e), i()
        }

        function r(e) {
            e.$track.fsTouch("destroy"), e.$bar.remove(), e.$content.off(k.namespace).contents().unwrap(), this.removeClass(e.thisClasses.join(" ")).off(k.namespace), this.attr("id") === e.rawGuid && this.removeAttr("id")
        }

        function o(t, n, a) {
            var i = a || t.duration,
                s = {};
            if ("number" !== e.type(n)) {
                var r = e(n);
                if (r.length > 0) {
                    var o = r.position();
                    n = t.horizontal ? o.left + t.$content.scrollLeft() : o.top + t.$content.scrollTop()
                } else n = "top" === n ? 0 : "bottom" === n ? t.horizontal ? t.$content[0].scrollWidth : t.$content[0].scrollHeight : t.$content.scrollTop()
            }
            s[t.horizontal ? "scrollLeft" : "scrollTop"] = n, t.$content.stop().animate(s, i)
        }

        function l(e) {
            e.$el.addClass(w.isSetup);
            var t = {},
                n = {},
                a = {},
                i = 0,
                s = !0;
            if (e.horizontal) {
                e.barHeight = e.$content[0].offsetHeight - e.$content[0].clientHeight, e.frameWidth = e.$content.outerWidth(), e.trackWidth = e.frameWidth - 2 * e.trackMargin, e.scrollWidth = e.$content[0].scrollWidth, e.ratio = e.trackWidth / e.scrollWidth, e.trackRatio = e.trackWidth / e.scrollWidth, e.handleWidth = e.handleSize > 0 ? e.handleSize : e.trackWidth * e.trackRatio, e.scrollRatio = (e.scrollWidth - e.frameWidth) / (e.trackWidth - e.handleWidth), e.handleBounds = {
                    left: 0,
                    right: e.trackWidth - e.handleWidth
                }, e.$content.css({
                    paddingBottom: e.barHeight + e.paddingBottom
                });
                i = e.$content.scrollLeft() * e.ratio, s = e.scrollWidth <= e.frameWidth, t = {
                    width: e.frameWidth
                }, n = {
                    width: e.trackWidth,
                    marginLeft: e.trackMargin,
                    marginRight: e.trackMargin
                }, a = {
                    width: e.handleWidth
                }
            } else {
                e.barWidth = e.$content[0].offsetWidth - e.$content[0].clientWidth, e.frameHeight = e.$content.outerHeight(), e.trackHeight = e.frameHeight - 2 * e.trackMargin, e.scrollHeight = e.$content[0].scrollHeight, e.ratio = e.trackHeight / e.scrollHeight, e.trackRatio = e.trackHeight / e.scrollHeight, e.handleHeight = e.handleSize > 0 ? e.handleSize : e.trackHeight * e.trackRatio, e.scrollRatio = (e.scrollHeight - e.frameHeight) / (e.trackHeight - e.handleHeight), e.handleBounds = {
                    top: 0,
                    bottom: e.trackHeight - e.handleHeight
                };
                i = e.$content.scrollTop() * e.ratio, s = e.scrollHeight <= e.frameHeight, t = {
                    height: e.frameHeight
                }, n = {
                    height: e.trackHeight,
                    marginBottom: e.trackMargin,
                    marginTop: e.trackMargin
                }, a = {
                    height: e.handleHeight
                }
            }
            s ? e.$el.removeClass(w.active) : e.$el.addClass(w.active), e.$bar.css(t), e.$track.css(n), e.$handle.css(a), e.panning = !1, m(e, i), u({
                data: e
            }), e.$el.removeClass(w.setup)
        }

        function u(e) {
            $.killEvent(e);
            var t = e.data,
                n = {};
            if (!t.panning) {
                if (t.horizontal) {
                    var a = t.$content.scrollLeft();
                    a < 0 && (a = 0), t.handleLeft = a / t.scrollRatio, t.handleLeft > t.handleBounds.right && (t.handleLeft = t.handleBounds.right), n = {
                        left: t.handleLeft
                    }
                } else {
                    var i = t.$content.scrollTop();
                    i < 0 && (i = 0), t.handleTop = i / t.scrollRatio, t.handleTop > t.handleBounds.bottom && (t.handleTop = t.handleBounds.bottom), n = {
                        top: t.handleTop
                    }
                }
                t.$handle.css(n)
            }
        }

        function c(e) {
            d(e, !0)
        }

        function d(e, t) {
            var n, a, i = e.data;
            if (i.horizontal) {
                var s = i.$content[0].scrollLeft,
                    r = i.$content[0].scrollWidth,
                    o = i.$content.outerWidth();
                if (n = e.originalEvent.deltaX * (!0 === t ? -1 : 1), !0 === t) return i.$content.scrollLeft(s - n), p(e);
                if ("left" === (a = n < 0 ? "right" : "left") && n > r - o - s) return i.$content.scrollLeft(r), p(e);
                if ("right" === a && -n > s) return i.$content.scrollLeft(0), p(e)
            } else {
                var l = i.$content[0].scrollTop,
                    u = i.$content[0].scrollHeight,
                    c = i.$content.outerHeight();
                if (n = e.originalEvent.deltaY * (!0 === t ? -1 : 1), !0 === t) return i.$content.scrollTop(l - n), p(e);
                if ("down" === (a = n < 0 ? "up" : "down") && n > u - c - l) return i.$content.scrollTop(u), p(e);
                if ("up" === a && -n > l) return i.$content.scrollTop(0), p(e)
            }
        }

        function p(e) {
            return $.killEvent(e), e.returnValue = !1, !1
        }

        function h(e) {
            var t, n = e.data,
                a = n.$track.offset();
            n.panning = !0, t = n.horizontal ? n.handleLeft = e.pageX - a.left - n.handleWidth / 2 : n.handleTop = e.pageY - a.top - n.handleHeight / 2, m(n, t)
        }

        function f(e) {
            var t, n = e.data;
            t = n.horizontal ? n.handleLeft + e.deltaX : n.handleTop + e.deltaY, m(n, t)
        }

        function g(e) {
            var t = e.data;
            t.panning = !1, t.horizontal ? t.handleLeft += e.deltaX : t.handleTop += e.deltaY
        }

        function m(e, t) {
            var n = {};
            e.horizontal ? (t < e.handleBounds.left && (t = e.handleBounds.left), t > e.handleBounds.right && (t = e.handleBounds.right), n = {
                left: t
            }, e.$content.scrollLeft(Math.round(t * e.scrollRatio))) : (t < e.handleBounds.top && (t = e.handleBounds.top), t > e.handleBounds.bottom && (t = e.handleBounds.bottom), n = {
                top: t
            }, e.$content.scrollTop(Math.round(t * e.scrollRatio))), e.$handle.css(n)
        }
        var b, v = t.Plugin("scrollbar", {
                widget: !0,
                defaults: {
                    customClass: "",
                    duration: 0,
                    handleSize: 0,
                    horizontal: !1,
                    mouseWheel: !0,
                    theme: "fs-light",
                    trackMargin: 0
                },
                classes: ["content", "bar", "track", "handle", "horizontal", "setup", "active"],
                methods: {
                    _construct: s,
                    _destruct: r,
                    _resize: a,
                    scroll: o,
                    resize: l
                }
            }),
            y = v.classes,
            w = y.raw,
            k = v.events,
            $ = v.functions,
            x = (t.$window, []);
        t.Ready(n)
    }),
    function(e) {
        "function" == typeof define && define.amd ? define(["jquery", "./core"], e) : e(jQuery, Formstone)
    }(function(e, t) {
        "use strict";

        function n(e) {
            if (e.touches = [], e.touching = !1, this.on(m.dragStart, b.killEvent), e.swipe && (e.pan = !0), e.scale && (e.axis = !1), e.axisX = "x" === e.axis, e.axisY = "y" === e.axis, t.support.pointer) {
                var n = "";
                !e.axis || e.axisX && e.axisY ? n = "none" : (e.axisX && (n += " pan-y"), e.axisY && (n += " pan-x")), h(this, n), this.on(m.pointerDown, e, i)
            } else this.on(m.touchStart, e, i).on(m.mouseDown, e, s)
        }

        function a() {
            this.off(m.namespace), h(this, "")
        }

        function i(e) {
            e.preventManipulation && e.preventManipulation();
            var t = e.data,
                n = e.originalEvent;
            if (n.type.match(/(up|end|cancel)$/i)) return void o(e);
            if (n.pointerId) {
                var a = !1;
                for (var i in t.touches) t.touches[i].id === n.pointerId && (a = !0, t.touches[i].pageX = n.pageX, t.touches[i].pageY = n.pageY);
                a || t.touches.push({
                    id: n.pointerId,
                    pageX: n.pageX,
                    pageY: n.pageY
                })
            } else t.touches = n.touches;
            n.type.match(/(down|start)$/i) ? s(e) : n.type.match(/move$/i) && r(e)
        }

        function s(n) {
            var a = n.data,
                s = "undefined" !== e.type(a.touches) && a.touches.length ? a.touches[0] : null;
            s && a.$el.off(m.mouseDown), a.touching || (a.startE = n.originalEvent, a.startX = s ? s.pageX : n.pageX, a.startY = s ? s.pageY : n.pageY, a.startT = (new Date).getTime(), a.scaleD = 1, a.passed = !1), a.$links && a.$links.off(m.click);
            var l = c(a.scale ? m.scaleStart : m.panStart, n, a.startX, a.startY, a.scaleD, 0, 0, "", "");
            if (a.scale && a.touches && a.touches.length >= 2) {
                var u = a.touches;
                a.pinch = {
                    startX: d(u[0].pageX, u[1].pageX),
                    startY: d(u[0].pageY, u[1].pageY),
                    startD: p(u[1].pageX - u[0].pageX, u[1].pageY - u[0].pageY)
                }, l.pageX = a.startX = a.pinch.startX, l.pageY = a.startY = a.pinch.startY
            }
            a.touching || (a.touching = !0, a.pan && !s && v.on(m.mouseMove, a, r).on(m.mouseUp, a, o), t.support.pointer ? v.on([m.pointerMove, m.pointerUp, m.pointerCancel].join(" "), a, i) : v.on([m.touchMove, m.touchEnd, m.touchCancel].join(" "), a, i), a.$el.trigger(l))
        }

        function r(t) {
            var n = t.data,
                a = "undefined" !== e.type(n.touches) && n.touches.length ? n.touches[0] : null,
                i = a ? a.pageX : t.pageX,
                s = a ? a.pageY : t.pageY,
                r = i - n.startX,
                l = s - n.startY,
                u = r > 0 ? "right" : "left",
                h = l > 0 ? "down" : "up",
                f = Math.abs(r) > y,
                g = Math.abs(l) > y;
            if (!n.passed && n.axis && (n.axisX && g || n.axisY && f)) o(t);
            else {
                !n.passed && (!n.axis || n.axis && n.axisX && f || n.axisY && g) && (n.passed = !0), n.passed && (b.killEvent(t), b.killEvent(n.startE));
                var v = !0,
                    w = c(n.scale ? m.scale : m.pan, t, i, s, n.scaleD, r, l, u, h);
                if (n.scale)
                    if (n.touches && n.touches.length >= 2) {
                        var k = n.touches;
                        n.pinch.endX = d(k[0].pageX, k[1].pageX), n.pinch.endY = d(k[0].pageY, k[1].pageY), n.pinch.endD = p(k[1].pageX - k[0].pageX, k[1].pageY - k[0].pageY), n.scaleD = n.pinch.endD / n.pinch.startD, w.pageX = n.pinch.endX, w.pageY = n.pinch.endY, w.scale = n.scaleD, w.deltaX = n.pinch.endX - n.pinch.startX, w.deltaY = n.pinch.endY - n.pinch.startY
                    } else n.pan || (v = !1);
                v && n.$el.trigger(w)
            }
        }

        function o(t) {
            var n = t.data,
                a = "undefined" !== e.type(n.touches) && n.touches.length ? n.touches[0] : null,
                i = a ? a.pageX : t.pageX,
                r = a ? a.pageY : t.pageY,
                o = i - n.startX,
                u = r - n.startY,
                d = (new Date).getTime(),
                p = n.scale ? m.scaleEnd : m.panEnd,
                h = o > 0 ? "right" : "left",
                f = u > 0 ? "down" : "up",
                g = Math.abs(o) > 1,
                k = Math.abs(u) > 1;
            if (n.swipe && Math.abs(o) > y && d - n.startT < w && (p = m.swipe), n.axis && (n.axisX && k || n.axisY && g) || g || k) {
                n.$links = n.$el.find("a");
                for (var $ = 0, x = n.$links.length; $ < x; $++) l(n.$links.eq($), n)
            }
            var C = c(p, t, i, r, n.scaleD, o, u, h, f);
            v.off([m.touchMove, m.touchEnd, m.touchCancel, m.mouseMove, m.mouseUp, m.pointerMove, m.pointerUp, m.pointerCancel].join(" ")), n.$el.trigger(C), n.touches = [], n.scale, a && (n.touchTimer = b.startTimer(n.touchTimer, 5, function() {
                n.$el.on(m.mouseDown, n, s)
            })), n.touching = !1
        }

        function l(t, n) {
            t.on(m.click, n, u);
            var a = e._data(t[0], "events").click;
            a.unshift(a.pop())
        }

        function u(e) {
            b.killEvent(e, !0), e.data.$links.off(m.click)
        }

        function c(t, n, a, i, s, r, o, l, u) {
            return e.Event(t, {
                originalEvent: n,
                bubbles: !0,
                pageX: a,
                pageY: i,
                scale: s,
                deltaX: r,
                deltaY: o,
                directionX: l,
                directionY: u
            })
        }

        function d(e, t) {
            return (e + t) / 2
        }

        function p(e, t) {
            return Math.sqrt(e * e + t * t)
        }

        function h(e, t) {
            e.css({
                "-ms-touch-action": t,
                "touch-action": t
            })
        }
        var f = !t.window.PointerEvent,
            g = t.Plugin("touch", {
                widget: !0,
                defaults: {
                    axis: !1,
                    pan: !1,
                    scale: !1,
                    swipe: !1
                },
                methods: {
                    _construct: n,
                    _destruct: a
                },
                events: {
                    pointerDown: f ? "MSPointerDown" : "pointerdown",
                    pointerUp: f ? "MSPointerUp" : "pointerup",
                    pointerMove: f ? "MSPointerMove" : "pointermove",
                    pointerCancel: f ? "MSPointerCancel" : "pointercancel"
                }
            }),
            m = g.events,
            b = g.functions,
            v = t.$window,
            y = 10,
            w = 50;
        m.pan = "pan", m.panStart = "panstart", m.panEnd = "panend", m.scale = "scale", m.scaleStart = "scalestart", m.scaleEnd = "scaleend", m.swipe = "swipe"
    });
var touch = "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
if (touch) try {
    for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si];
        if (styleSheet.rules)
            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) styleSheet.rules[ri].selectorText && styleSheet.rules[ri].selectorText.match(":hover") && styleSheet.deleteRule(ri)
    }
} catch (e) {}! function(e, t) {
    "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.Typed = t() : e.Typed = t()
}(this, function() {
    return function(e) {
        function t(a) {
            if (n[a]) return n[a].exports;
            var i = n[a] = {
                exports: {},
                id: a,
                loaded: !1
            };
            return e[a].call(i.exports, i, i.exports, t), i.loaded = !0, i.exports
        }
        var n = {};
        return t.m = e, t.c = n, t.p = "", t(0)
    }([function(e, t, n) {
        "use strict";

        function a(e, t) {
            if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function")
        }
        Object.defineProperty(t, "__esModule", {
            value: !0
        });
        var i = function() {
                function e(e, t) {
                    for (var n = 0; n < t.length; n++) {
                        var a = t[n];
                        a.enumerable = a.enumerable || !1, a.configurable = !0, "value" in a && (a.writable = !0), Object.defineProperty(e, a.key, a)
                    }
                }
                return function(t, n, a) {
                    return n && e(t.prototype, n), a && e(t, a), t
                }
            }(),
            s = n(1),
            r = n(3),
            o = function() {
                function e(t, n) {
                    a(this, e), s.initializer.load(this, n, t), this.begin()
                }
                return i(e, [{
                    key: "toggle",
                    value: function() {
                        this.pause.status ? this.start() : this.stop()
                    }
                }, {
                    key: "stop",
                    value: function() {
                        this.typingComplete || this.pause.status || (this.toggleBlinking(!0), this.pause.status = !0, this.options.onStop(this.arrayPos, this))
                    }
                }, {
                    key: "start",
                    value: function() {
                        this.typingComplete || this.pause.status && (this.pause.status = !1, this.pause.typewrite ? this.typewrite(this.pause.curString, this.pause.curStrPos) : this.backspace(this.pause.curString, this.pause.curStrPos), this.options.onStart(this.arrayPos, this))
                    }
                }, {
                    key: "destroy",
                    value: function() {
                        this.reset(!1), this.options.onDestroy(this)
                    }
                }, {
                    key: "reset",
                    value: function() {
                        var e = arguments.length <= 0 || arguments[0] === undefined || arguments[0];
                        clearInterval(this.timeout), this.replaceText(""), this.cursor && this.cursor.parentNode && (this.cursor.parentNode.removeChild(this.cursor), this.cursor = null), this.strPos = 0, this.arrayPos = 0, this.curLoop = 0, e && (this.insertCursor(), this.options.onReset(this), this.begin())
                    }
                }, {
                    key: "begin",
                    value: function() {
                        var e = this;
                        this.typingComplete = !1, this.shuffleStringsIfNeeded(this), this.insertCursor(), this.bindInputFocusEvents && this.bindFocusEvents(), this.timeout = setTimeout(function() {
                            e.currentElContent && 0 !== e.currentElContent.length ? e.backspace(e.currentElContent, e.currentElContent.length) : e.typewrite(e.strings[e.sequence[e.arrayPos]], e.strPos)
                        }, this.startDelay)
                    }
                }, {
                    key: "typewrite",
                    value: function(e, t) {
                        var n = this;
                        this.fadeOut && this.el.classList.contains(this.fadeOutClass) && (this.el.classList.remove(this.fadeOutClass), this.cursor && this.cursor.classList.remove(this.fadeOutClass));
                        var a = this.humanizer(this.typeSpeed),
                            i = 1;
                        if (!0 === this.pause.status) return void this.setPauseStatus(e, t, !0);
                        this.timeout = setTimeout(function() {
                            t = r.htmlParser.typeHtmlChars(e, t, n);
                            var a = 0,
                                s = e.substr(t);
                            if ("^" === s.charAt(0) && /^\^\d+/.test(s)) {
                                var o = 1;
                                s = /\d+/.exec(s)[0], o += s.length, a = parseInt(s), n.temporaryPause = !0, n.options.onTypingPaused(n.arrayPos, n), e = e.substring(0, t) + e.substring(t + o), n.toggleBlinking(!0)
                            }
                            if ("`" === s.charAt(0)) {
                                for (;
                                    "`" !== e.substr(t + i).charAt(0) && (i++, !(t + i > e.length)););
                                var l = e.substring(0, t),
                                    u = e.substring(l.length + 1, t + i),
                                    c = e.substring(t + i + 1);
                                e = l + u + c, i--
                            }
                            n.timeout = setTimeout(function() {
                                n.toggleBlinking(!1), t === e.length ? n.doneTyping(e, t) : n.keepTyping(e, t, i), n.temporaryPause && (n.temporaryPause = !1, n.options.onTypingResumed(n.arrayPos, n))
                            }, a)
                        }, a)
                    }
                }, {
                    key: "keepTyping",
                    value: function(e, t, n) {
                        0 === t && (this.toggleBlinking(!1), this.options.preStringTyped(this.arrayPos, this)), t += n;
                        var a = e.substr(0, t);
                        this.replaceText(a), this.typewrite(e, t)
                    }
                }, {
                    key: "doneTyping",
                    value: function(e, t) {
                        var n = this;
                        this.options.onStringTyped(this.arrayPos, this), this.toggleBlinking(!0), this.arrayPos === this.strings.length - 1 && (this.complete(), !1 === this.loop || this.curLoop === this.loopCount) || (this.timeout = setTimeout(function() {
                            n.backspace(e, t)
                        }, this.backDelay))
                    }
                }, {
                    key: "backspace",
                    value: function(e, t) {
                        var n = this;
                        if (!0 === this.pause.status) return void this.setPauseStatus(e, t, !0);
                        if (this.fadeOut) return this.initFadeOut();
                        this.toggleBlinking(!1);
                        var a = this.humanizer(this.backSpeed);
                        this.timeout = setTimeout(function() {
                            t = r.htmlParser.backSpaceHtmlChars(e, t, n);
                            var a = e.substr(0, t);
                            if (n.replaceText(a), n.smartBackspace) {
                                var i = n.strings[n.arrayPos + 1];
                                i && a === i.substr(0, t) ? n.stopNum = t : n.stopNum = 0
                            }
                            t > n.stopNum ? (t--, n.backspace(e, t)) : t <= n.stopNum && (n.arrayPos++, n.arrayPos === n.strings.length ? (n.arrayPos = 0, n.options.onLastStringBackspaced(), n.shuffleStringsIfNeeded(), n.begin()) : n.typewrite(n.strings[n.sequence[n.arrayPos]], t))
                        }, a)
                    }
                }, {
                    key: "complete",
                    value: function() {
                        this.options.onComplete(this), this.loop ? this.curLoop++ : this.typingComplete = !0
                    }
                }, {
                    key: "setPauseStatus",
                    value: function(e, t, n) {
                        this.pause.typewrite = n, this.pause.curString = e, this.pause.curStrPos = t
                    }
                }, {
                    key: "toggleBlinking",
                    value: function(e) {
                        if (this.cursor && !this.pause.status && this.cursorBlinking !== e) {
                            this.cursorBlinking = e;
                            var t = e ? "infinite" : 0;
                            this.cursor.style.animationIterationCount = t
                        }
                    }
                }, {
                    key: "humanizer",
                    value: function(e) {
                        return Math.round(Math.random() * e / 2) + e
                    }
                }, {
                    key: "shuffleStringsIfNeeded",
                    value: function() {
                        this.shuffle && (this.sequence = this.sequence.sort(function() {
                            return Math.random() - .5
                        }))
                    }
                }, {
                    key: "initFadeOut",
                    value: function() {
                        var e = this;
                        return this.el.className += " " + this.fadeOutClass, this.cursor && (this.cursor.className += " " + this.fadeOutClass), setTimeout(function() {
                            e.arrayPos++, e.replaceText(""), e.strings.length > e.arrayPos ? e.typewrite(e.strings[e.sequence[e.arrayPos]], 0) : (e.typewrite(e.strings[0], 0), e.arrayPos = 0)
                        }, this.fadeOutDelay)
                    }
                }, {
                    key: "replaceText",
                    value: function(e) {
                        this.attr ? this.el.setAttribute(this.attr, e) : this.isInput ? this.el.value = e : "html" === this.contentType ? this.el.innerHTML = e : this.el.textContent = e
                    }
                }, {
                    key: "bindFocusEvents",
                    value: function() {
                        var e = this;
                        this.isInput && (this.el.addEventListener("focus", function() {
                            e.stop()
                        }), this.el.addEventListener("blur", function() {
                            e.el.value && 0 !== e.el.value.length || e.start()
                        }))
                    }
                }, {
                    key: "insertCursor",
                    value: function() {
                        this.showCursor && (this.cursor || (this.cursor = document.createElement("span"), this.cursor.className = "typed-cursor", this.cursor.innerHTML = this.cursorChar, this.el.parentNode && this.el.parentNode.insertBefore(this.cursor, this.el.nextSibling)))
                    }
                }]), e
            }();
        t["default"] = o, e.exports = t["default"]
    }, function(e, t, n) {
        "use strict";

        function a(e) {
            return e && e.__esModule ? e : {
                default: e
            }
        }

        function i(e, t) {
            if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function")
        }
        Object.defineProperty(t, "__esModule", {
            value: !0
        });
        var s = Object.assign || function(e) {
                for (var t = 1; t < arguments.length; t++) {
                    var n = arguments[t];
                    for (var a in n) Object.prototype.hasOwnProperty.call(n, a) && (e[a] = n[a])
                }
                return e
            },
            r = function() {
                function e(e, t) {
                    for (var n = 0; n < t.length; n++) {
                        var a = t[n];
                        a.enumerable = a.enumerable || !1, a.configurable = !0, "value" in a && (a.writable = !0), Object.defineProperty(e, a.key, a)
                    }
                }
                return function(t, n, a) {
                    return n && e(t.prototype, n), a && e(t, a), t
                }
            }(),
            o = n(2),
            l = a(o),
            u = function() {
                function e() {
                    i(this, e)
                }
                return r(e, [{
                    key: "load",
                    value: function(e, t, n) {
                        if (e.el = "string" == typeof n ? document.querySelector(n) : n, e.options = s({}, l["default"], t), e.isInput = "input" === e.el.tagName.toLowerCase(), e.attr = e.options.attr, e.bindInputFocusEvents = e.options.bindInputFocusEvents, e.showCursor = !e.isInput && e.options.showCursor, e.cursorChar = e.options.cursorChar, e.cursorBlinking = !0, e.elContent = e.attr ? e.el.getAttribute(e.attr) : e.el.textContent, e.contentType = e.options.contentType, e.typeSpeed = e.options.typeSpeed, e.startDelay = e.options.startDelay, e.backSpeed = e.options.backSpeed, e.smartBackspace = e.options.smartBackspace, e.backDelay = e.options.backDelay, e.fadeOut = e.options.fadeOut, e.fadeOutClass = e.options.fadeOutClass, e.fadeOutDelay = e.options.fadeOutDelay, e.isPaused = !1, e.strings = e.options.strings.map(function(e) {
                                return e.trim()
                            }), "string" == typeof e.options.stringsElement ? e.stringsElement = document.querySelector(e.options.stringsElement) : e.stringsElement = e.options.stringsElement, e.stringsElement) {
                            e.strings = [], e.stringsElement.style.display = "none";
                            var a = Array.prototype.slice.apply(e.stringsElement.children),
                                i = a.length;
                            if (i)
                                for (var r = 0; r < i; r += 1) {
                                    var o = a[r];
                                    e.strings.push(o.innerHTML.trim())
                                }
                        }
                        e.strPos = 0, e.arrayPos = 0, e.stopNum = 0, e.loop = e.options.loop, e.loopCount = e.options.loopCount, e.curLoop = 0, e.shuffle = e.options.shuffle, e.sequence = [], e.pause = {
                            status: !1,
                            typewrite: !0,
                            curString: "",
                            curStrPos: 0
                        }, e.typingComplete = !1;
                        for (var r in e.strings) e.sequence[r] = r;
                        e.currentElContent = this.getCurrentElContent(e), e.autoInsertCss = e.options.autoInsertCss, this.appendAnimationCss(e)
                    }
                }, {
                    key: "getCurrentElContent",
                    value: function(e) {
                        return e.attr ? e.el.getAttribute(e.attr) : e.isInput ? e.el.value : "html" === e.contentType ? e.el.innerHTML : e.el.textContent
                    }
                }, {
                    key: "appendAnimationCss",
                    value: function(e) {
                        if (e.autoInsertCss && e.showCursor && e.fadeOut) {
                            var t = document.createElement("style");
                            t.type = "text/css";
                            var n = "";
                            e.showCursor && (n += "\n        .typed-cursor{\n          opacity: 1;\n          animation: typedjsBlink 0.7s infinite;\n          -webkit-animation: typedjsBlink 0.7s infinite;\n                  animation: typedjsBlink 0.7s infinite;\n        }\n        @keyframes typedjsBlink{\n          50% { opacity: 0.0; }\n        }\n        @-webkit-keyframes typedjsBlink{\n          0% { opacity: 1; }\n          50% { opacity: 0.0; }\n          100% { opacity: 1; }\n        }\n      "), e.fadeOut && (n += "\n        .typed-fade-out{\n          opacity: 0;\n          transition: opacity .25s;\n          -webkit-animation: 0;\n                  animation: 0;\n        }\n      "), 0 !== t.length && (t.innerHTML = n, document.head.appendChild(t))
                        }
                    }
                }]), e
            }();
        t["default"] = u;
        var c = new u;
        t.initializer = c
    }, function(e, t) {
        "use strict";
        Object.defineProperty(t, "__esModule", {
            value: !0
        });
        var n = {
            strings: ["These are the default values...", "You know what you should do?", "Use your own!", "Have a great day!"],
            stringsElement: null,
            typeSpeed: 0,
            startDelay: 0,
            backSpeed: 0,
            smartBackspace: !0,
            shuffle: !1,
            backDelay: 700,
            fadeOut: !1,
            fadeOutClass: "typed-fade-out",
            fadeOutDelay: 500,
            loop: !1,
            loopCount: Infinity,
            showCursor: !0,
            cursorChar: "|",
            autoInsertCss: !0,
            attr: null,
            bindInputFocusEvents: !1,
            contentType: "html",
            onComplete: function() {},
            preStringTyped: function() {},
            onStringTyped: function() {},
            onLastStringBackspaced: function() {},
            onTypingPaused: function() {},
            onTypingResumed: function() {},
            onReset: function() {},
            onStop: function() {},
            onStart: function() {},
            onDestroy: function() {}
        };
        t["default"] = n, e.exports = t["default"]
    }, function(e, t) {
        "use strict";

        function n(e, t) {
            if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function")
        }
        Object.defineProperty(t, "__esModule", {
            value: !0
        });
        var a = function() {
                function e(e, t) {
                    for (var n = 0; n < t.length; n++) {
                        var a = t[n];
                        a.enumerable = a.enumerable || !1, a.configurable = !0, "value" in a && (a.writable = !0), Object.defineProperty(e, a.key, a)
                    }
                }
                return function(t, n, a) {
                    return n && e(t.prototype, n), a && e(t, a), t
                }
            }(),
            i = function() {
                function e() {
                    n(this, e)
                }
                return a(e, [{
                    key: "typeHtmlChars",
                    value: function(e, t, n) {
                        if ("html" !== n.contentType) return t;
                        var a = e.substr(t).charAt(0);
                        if ("<" === a || "&" === a) {
                            var i = "";
                            for (i = "<" === a ? ">" : ";"; e.substr(t + 1).charAt(0) !== i && !(++t + 1 > e.length););
                            t++
                        }
                        return t
                    }
                }, {
                    key: "backSpaceHtmlChars",
                    value: function(e, t, n) {
                        if ("html" !== n.contentType) return t;
                        var a = e.substr(t).charAt(0);
                        if (">" === a || ";" === a) {
                            var i = "";
                            for (i = ">" === a ? "<" : "&"; e.substr(t - 1).charAt(0) !== i && !(--t < 0););
                            t--
                        }
                        return t
                    }
                }]), e
            }();
        t["default"] = i;
        var s = new i;
        t.htmlParser = s
    }])
}), jQuery.easing.jswing = jQuery.easing.swing, jQuery.extend(jQuery.easing, {
    def: "easeOutQuad",
    swing: function(e, t, n, a, i) {
        return jQuery.easing[jQuery.easing.def](e, t, n, a, i)
    },
    easeInQuad: function(e, t, n, a, i) {
        return a * (t /= i) * t + n
    },
    easeOutQuad: function(e, t, n, a, i) {
        return -a * (t /= i) * (t - 2) + n
    },
    easeInOutQuad: function(e, t, n, a, i) {
        return (t /= i / 2) < 1 ? a / 2 * t * t + n : -a / 2 * (--t * (t - 2) - 1) + n
    },
    easeInCubic: function(e, t, n, a, i) {
        return a * (t /= i) * t * t + n
    },
    easeOutCubic: function(e, t, n, a, i) {
        return a * ((t = t / i - 1) * t * t + 1) + n
    },
    easeInOutCubic: function(e, t, n, a, i) {
        return (t /= i / 2) < 1 ? a / 2 * t * t * t + n : a / 2 * ((t -= 2) * t * t + 2) + n
    },
    easeInQuart: function(e, t, n, a, i) {
        return a * (t /= i) * t * t * t + n
    },
    easeOutQuart: function(e, t, n, a, i) {
        return -a * ((t = t / i - 1) * t * t * t - 1) + n
    },
    easeInOutQuart: function(e, t, n, a, i) {
        return (t /= i / 2) < 1 ? a / 2 * t * t * t * t + n : -a / 2 * ((t -= 2) * t * t * t - 2) + n
    },
    easeInQuint: function(e, t, n, a, i) {
        return a * (t /= i) * t * t * t * t + n
    },
    easeOutQuint: function(e, t, n, a, i) {
        return a * ((t = t / i - 1) * t * t * t * t + 1) + n
    },
    easeInOutQuint: function(e, t, n, a, i) {
        return (t /= i / 2) < 1 ? a / 2 * t * t * t * t * t + n : a / 2 * ((t -= 2) * t * t * t * t + 2) + n
    },
    easeInSine: function(e, t, n, a, i) {
        return -a * Math.cos(t / i * (Math.PI / 2)) + a + n
    },
    easeOutSine: function(e, t, n, a, i) {
        return a * Math.sin(t / i * (Math.PI / 2)) + n
    },
    easeInOutSine: function(e, t, n, a, i) {
        return -a / 2 * (Math.cos(Math.PI * t / i) - 1) + n
    },
    easeInExpo: function(e, t, n, a, i) {
        return 0 == t ? n : a * Math.pow(2, 10 * (t / i - 1)) + n
    },
    easeOutExpo: function(e, t, n, a, i) {
        return t == i ? n + a : a * (1 - Math.pow(2, -10 * t / i)) + n
    },
    easeInOutExpo: function(e, t, n, a, i) {
        return 0 == t ? n : t == i ? n + a : (t /= i / 2) < 1 ? a / 2 * Math.pow(2, 10 * (t - 1)) + n : a / 2 * (2 - Math.pow(2, -10 * --t)) + n
    },
    easeInCirc: function(e, t, n, a, i) {
        return -a * (Math.sqrt(1 - (t /= i) * t) - 1) + n
    },
    easeOutCirc: function(e, t, n, a, i) {
        return a * Math.sqrt(1 - (t = t / i - 1) * t) + n
    },
    easeInOutCirc: function(e, t, n, a, i) {
        return (t /= i / 2) < 1 ? -a / 2 * (Math.sqrt(1 - t * t) - 1) + n : a / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + n
    },
    easeInElastic: function(e, t, n, a, i) {
        var s = 1.70158,
            r = 0,
            o = a;
        if (0 == t) return n;
        if (1 == (t /= i)) return n + a;
        if (r || (r = .3 * i), o < Math.abs(a)) {
            o = a;
            var s = r / 4
        } else var s = r / (2 * Math.PI) * Math.asin(a / o);
        return -o * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * i - s) * (2 * Math.PI) / r) + n
    },
    easeOutElastic: function(e, t, n, a, i) {
        var s = 1.70158,
            r = 0,
            o = a;
        if (0 == t) return n;
        if (1 == (t /= i)) return n + a;
        if (r || (r = .3 * i), o < Math.abs(a)) {
            o = a;
            var s = r / 4
        } else var s = r / (2 * Math.PI) * Math.asin(a / o);
        return o * Math.pow(2, -10 * t) * Math.sin((t * i - s) * (2 * Math.PI) / r) + a + n
    },
    easeInOutElastic: function(e, t, n, a, i) {
        var s = 1.70158,
            r = 0,
            o = a;
        if (0 == t) return n;
        if (2 == (t /= i / 2)) return n + a;
        if (r || (r = i * (.3 * 1.5)), o < Math.abs(a)) {
            o = a;
            var s = r / 4
        } else var s = r / (2 * Math.PI) * Math.asin(a / o);
        return t < 1 ? o * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * i - s) * (2 * Math.PI) / r) * -.5 + n : o * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * i - s) * (2 * Math.PI) / r) * .5 + a + n
    },
    easeInBack: function(e, t, n, a, i, s) {
        return s == undefined && (s = 1.70158), a * (t /= i) * t * ((s + 1) * t - s) + n
    },
    easeOutBack: function(e, t, n, a, i, s) {
        return s == undefined && (s = 1.70158), a * ((t = t / i - 1) * t * ((s + 1) * t + s) + 1) + n
    },
    easeInOutBack: function(e, t, n, a, i, s) {
        return s == undefined && (s = 1.70158), (t /= i / 2) < 1 ? a / 2 * (t * t * ((1 + (s *= 1.525)) * t - s)) + n : a / 2 * ((t -= 2) * t * ((1 + (s *= 1.525)) * t + s) + 2) + n
    },
    easeInBounce: function(e, t, n, a, i) {
        return a - jQuery.easing.easeOutBounce(e, i - t, 0, a, i) + n
    },
    easeOutBounce: function(e, t, n, a, i) {
        return (t /= i) < 1 / 2.75 ? a * (7.5625 * t * t) + n : t < 2 / 2.75 ? a * (7.5625 * (t -= 1.5 / 2.75) * t + .75) + n : t < 2.5 / 2.75 ? a * (7.5625 * (t -= 2.25 / 2.75) * t + .9375) + n : a * (7.5625 * (t -= 2.625 / 2.75) * t + .984375) + n
    },
    easeInOutBounce: function(e, t, n, a, i) {
        return t < i / 2 ? .5 * jQuery.easing.easeInBounce(e, 2 * t, 0, a, i) + n : .5 * jQuery.easing.easeOutBounce(e, 2 * t - i, 0, a, i) + .5 * a + n
    },
    nicksEase: function(e, t, n, a) {
        var i = (e /= a) * e,
            s = i * e;
        return jQuery.easing.nicksEase(t + n * (-.652500000000001 * s * i + 4.8575 * i * i + -9.505 * s + 6.1 * i + .2 * e))
    }
});
var $body = $("html, body"),
    $window = $(window),
    $document = $(document),
    desktop = 1024;
api(), planChange(), volumeRateAdjust(), setCurrentDate(), listSourceLoop(), removeAnimation(), addScrollbar(), setTimeout(function() {
        homepageDemo()
    }, 2500), menu(),
    function() {}.call(this);